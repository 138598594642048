import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from '../styles/Login';
import Users from '../services/Users';

const MultiFactor = () => {
    const classes = useStyles();
    const [formData, setFormData] = useState();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const authenticate = async (event) => {
        event.preventDefault();
        setLoading(true);

        const user = await Users.validate(formData);

        if (user.error) {
            setError(user.error);
            setLoading(false);
        } else {
            user.selectedStore = 0;
            localStorage.setItem('user-data', JSON.stringify(user));
            window.location.href = '/';
        }
    }

    const inputHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        setError(null);
        setFormData({
            ...formData,
            [nam]: val,
            code: code
        });
    }

    return (
        <Container component="main" className={classes.root}>
            <CssBaseline />
            <div className={classes.paper}>
                <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="logo" style={{ height: '50px' }} />
                <Typography variant="body2" align="center" style={{ marginTop: '50px' }}>
                    Digite o token de validação enviado para seu e-mail
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="multifactorToken"
                        name="multifactorToken"
                        autoFocus
                        onChange={inputHandler}
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        disabled={loading}
                        onClick={authenticate}
                    >
                        Entrar
                        <span style={{ paddingRight: '10px' }}></span>
                        {loading && <CircularProgress size={20} />}
                    </Button>
                </form>
            </div>
            <Box>
                <Typography variant="body2" align="center">
                    {'Copyright © '}
                    <Link href="https://www.compassio.com.br/" target="_blank">
                        Compassio
                    </Link>{' '}
                    {new Date().getFullYear()}
                </Typography>
            </Box>
        </Container>
    );
}

export default MultiFactor;