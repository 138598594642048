import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    left: ${props => props.isDrawerOpen ? '200px' : '0px' };
    top: 60px;
    right: 0px;
    bottom: 0px;
    overflow-y: auto;
    padding: 15px 20px;
    text-align: justify;
    transition: left 300ms;
`;
