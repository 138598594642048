const user = JSON.parse(localStorage.getItem('user-data'));

const list = (page = 0, limit = 30, filters = '') => {
    return new Promise((resolve, reject) => {

        if (!user || !user.stores) {
            localStorage.removeItem('user-data');
            window.location.reload();
        }

        const headers = new Headers();
        headers.append("x-api-key", user.stores[user.selectedStore].storeId);
        headers.append("token", user.token);

        const url = '/api/orders?page=' + (page + 1) + '&size=' + limit + '&hybrid=true&sort=DESC' + filters;
        const options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('multifactor');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const retrieve = (id) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("x-api-key", user.stores[user.selectedStore].storeId);
        headers.append("token", user.token);

        let url = '/api/orders/' + id;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('multifactor');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const update = (id, order) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("x-api-key", user.stores[user.selectedStore].storeId);
        headers.append("token", user.token);

        let url = '/api/orders/' + id;
        let options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(order)
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('multifactor');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const deleteOrder = (id) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("x-api-key", user.stores[user.selectedStore].storeId);
        headers.append("token", user.token);

        let url = '/api/orders/' + id;
        let options = {
            method: 'DELETE',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('multifactor');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const orderStatus = (filter) => {
    let status = [
        {
            id: 0,
            label: "Novo"
        },
        {
            id: 1,
            label: "Aprovado"
        },
        {
            id: 2,
            label: "Faturado"
        },
        {
            id: 3,
            label: "Enviado"
        },
        {
            id: 4,
            label: "Entregue"
        },
        {
            id: 5,
            label: "Troca/Devolução"
        },
        {
            id: 8,
            label: "Cancelado Parcial"
        },
        {
            id: 9,
            label: "Cancelado"
        }
    ]

    if (filter || filter === 0) {
        if (typeof (filter) === "string") {
            status = status.find(x => x.label === filter)
        } else {
            status = status.find(x => x.id === filter)
        }
    }

    return status;
}

const orders = { list, retrieve, update, deleteOrder, orderStatus };

export default orders;