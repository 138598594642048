import React from 'react';
import { Divider } from '@material-ui/core';
import { Container, Title } from './styles';

function PageTitle(props) {
    return (
        <Container>
            <Title>{props.title}</Title>
            <Divider />
        </Container>
    )
}

export default PageTitle;