import styled from 'styled-components';


export const Container = styled.div`
    padding: 0px 10px;
    position: fixed;
    left: ${props => props.isDrawerOpen ? '200px' : '0px' };
    right: 0px;
    height: 60px;
    background: #005959;
    color: #FFF;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-between;
    align-items: center;
    transition: left 300ms;
`;