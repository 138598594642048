import React from 'react';
import { useForm } from "react-hook-form";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
} from '@material-ui/core';

import { DateTimeField } from '../../components/MaskedFields';

function EditInvoice(props) {
    const { register, handleSubmit, errors } = useForm();

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">
                Editar nota fiscal
                {!props.new &&
                    <small style={{ fontSize: '0.8rem' }}><br />ATENÇÃO! As informações editadas nesta seção não irão refletir no marketplace</small>
                }
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="number"
                            label="Número"
                            defaultValue={props.data?.number}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.number}
                            helperText={errors.number?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="serial"
                            label="Série"
                            defaultValue={props.data?.serial}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.serial}
                            helperText={errors.serial?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="key"
                            label="Chave"
                            defaultValue={props.data?.key}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputProps={{
                                maxLength: 44
                            }}
                            inputRef={register({
                                required: "*Campo obrigatório",
                                minLength: {
                                    value: 44,
                                    message: '*A chave da NFe precisa ter 44 caracteres'
                                },
                                maxLength: {
                                    value: 44,
                                    message: '*A chave da NFe precisa ter 44 caracteres'
                                }
                            })}
                            error={!!errors.key}
                            helperText={errors.key?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimeField
                            name="date"
                            label="Data/Hora"
                            value={props.data?.date}
                            onChange={props.handleChangeDateField}
                            fullWidth
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit(props.confirm)}
                >
                    Salvar
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={props.cancel}
                >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditInvoice;