import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
} from '@material-ui/core';

import { useForm } from "react-hook-form";

function EditAddress(props) {
    const { register, handleSubmit, errors } = useForm();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">{props.type === 'billing' ? 'Editar dados de cobrança' : 'Editar dados de envio'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="name"
                            label="Nome"
                            defaultValue={props.data.name}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    </Grid>
                    {props.type === 'billing' &&
                        <Grid item xs={12}>
                            <TextField
                                name="vatNumber"
                                label="CPF/CNPJ"
                                defaultValue={props.data.vatNumber}
                                onChange={props.handleChangeField}
                                fullWidth
                                inputRef={register({ required: "*Campo obrigatório" })}
                                error={!!errors.vatNumber}
                                helperText={errors.vatNumber?.message}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            name="phone"
                            label="Telefone"
                            defaultValue={props.data.phone}
                            onChange={props.handleChangeField}
                            fullWidth
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            label="E-Mail"
                            defaultValue={props.data.email}
                            onChange={props.handleChangeField}
                            fullWidth
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="streetName"
                            label="Endereço"
                            defaultValue={props.data.streetName}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.streetName}
                            helperText={errors.streetName?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="streetNumber"
                            label="Numero"
                            defaultValue={props.data.streetNumber}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.streetNumber}
                            helperText={errors.streetNumber?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="streetComplement"
                            label="Complemento"
                            defaultValue={props.data.streetComplement}
                            onChange={props.handleChangeField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="district"
                            label="Bairro"
                            defaultValue={props.data.district}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.district}
                            helperText={errors.district?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="city"
                            label="Cidade"
                            defaultValue={props.data.city}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.city}
                            helperText={errors.city?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="state"
                            label="Estado"
                            defaultValue={props.data.state}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.state}
                            helperText={errors.state?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="zipCode"
                            label="CEP"
                            defaultValue={props.data.zipCode}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.zipCode}
                            helperText={errors.zipCode?.message}
                            disabled={props.data.marketplace === 1 || props.data.marketplace === 7 || props.data.marketplace === 4}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    autoFocus
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit(props.confirm)}
                >
                    Salvar
                </Button>
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={props.cancel}
                >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditAddress;