import styled from 'styled-components';

export const Container = styled.aside`
    width: ${props => props.isDrawerOpen ? '200px' : '0px' };
    position: absolute;
    height: 100%;
    background: #005959;
    color: #FFF;
    transition: width 300ms;
`;

export const Logo = styled.div`
    display: ${props => props.isDrawerOpen ? 'inherit' : 'none' } !important;
    width: ${props => props.isDrawerOpen ? '200px' : '0px' };
    height: ${props => props.isDrawerOpen ? '60px' : '0px' };
    padding: 20px 30px;
    display: flex;
    align-items: center;
    transition: width 300ms;
`;

export const Menu = styled.ul`
    display: ${props => props.isDrawerOpen ? 'inherit' : 'none' };
    width: ${props => props.isDrawerOpen ? '200px' : '0px' };
    margin-top: 30px;
    transition: width 300ms;
    color: inherit;
`;

export const SubMenu = styled.ul`
    width: 100%;
    color: inherit;
`;

export const SubMenuItem = styled.li`
    padding: 12px 20px 12px 50px;
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
        background: #196969;
    }
    color: inherit;
`;

export const Link = styled.a`
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
`