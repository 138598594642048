import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableHeaderCell, TableCell, TablePagination } from './styles';
import { Paper } from '@material-ui/core';

function DataTable(props) {
    return (
        <Paper>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow key="datable-header">
                            {props.columns.map((column, index) => (
                                <TableHeaderCell key={column.id+'-'+index} align={column.align}>{column.label}</TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.docs && props.docs.length > 0 ? props.docs.map((doc, index) => (
                            <TableRow key={"row-"+index}>
                                {props.columns.map((column) => {
                                    let value;

                                    if (column.type === 'hybrid') {
                                        value = doc;
                                    } else {
                                        value = doc[column.id];
                                    }

                                    return (
                                        <TableCell key={doc._id + '-' + index + '-' + column.id} align={column.align} style={{ width: column.width }}>
                                            {column.format ? column.format(value) : value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )) : (
                            <TableCell align="center" colSpan={props.columns.length}>Não existem informações para serem exibidas</TableCell>
                        )}
                        {props.footer}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.count && props.docs.length > 0) ? (
                <TablePagination
                    rowsPerPageOptions={[30, 50, 100]}
                    component="div"
                    count={props.count}
                    rowsPerPage={props.size}
                    page={props.page}
                    onChangePage={props.handleChangePage}
                    onChangeRowsPerPage={props.handleChangeSize}
                    labelRowsPerPage="Registros por página"
                    nextIconButtonText="Próxima página"
                    backIconButtonText="Página anterior"
                />
            ) : ''}
        </Paper>
    )
}

export default DataTable;