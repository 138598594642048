import React from 'react';
import { Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import StoreMenu from '../StoreMenu';
import UserMenu from '../UserMenu';

import { Container } from './styles';

function Header(props) {
    const cursorPointer = { cursor: 'pointer', height: '100%' };

    return (
        <Container isDrawerOpen={props.isDrawerOpen}>
            <MenuIcon onClick={props.handleDrawer} style={cursorPointer} />
            <Box style={{ height: '100%' }}>
                <StoreMenu />
                <UserMenu />
            </Box>
        </Container>
    )
}

export default Header;