import React from 'react';

function MarketplaceLogo(props) {
    const imageUrl = process.env.PUBLIC_URL;
    
    let marketplaceTitle;
    let marketplaceLogo;

    switch (props.marketplace) {
        case 1:
            marketplaceTitle = 'Amazon';
            marketplaceLogo = imageUrl + '/amazon.png';
            break;

        case 4:
            marketplaceTitle = 'Magazine Luiza';
            marketplaceLogo = imageUrl + '/magalu.png';
            break;

        case 6:
            marketplaceTitle = 'Americanas';
            marketplaceLogo = imageUrl + '/b2w.png';
            break;

        case 7:
            marketplaceTitle = 'Mercado Livre';
            marketplaceLogo = imageUrl + '/mercado_livre.png';
            break;

        case 10:
            marketplaceTitle = 'Dafiti';
            marketplaceLogo = imageUrl + '/dafiti.png';
            break;

        case 11:
            marketplaceTitle = 'Magento';
            marketplaceLogo = imageUrl + '/magento.png';
            break;

        case 12:
            marketplaceTitle = 'Empório Bud';
            marketplaceLogo = imageUrl + '/emporio_bud.png';
            break;

        case 13:
            marketplaceTitle = 'GPA';
            marketplaceLogo = imageUrl + '/gpa.png';
            break;

        case 14:
            marketplaceTitle = 'OmniChat';
            marketplaceLogo = imageUrl + '/omnichat.png';
            break;
    
        case 15:
            marketplaceTitle = 'Shopee';
            marketplaceLogo = imageUrl + '/shopee.png';
            break;
    
        default:
            marketplaceTitle = props.marketplace;
            marketplaceLogo = imageUrl + '/xxxx.png';
            break;
    }

    return (
        <img alt={marketplaceTitle} src={marketplaceLogo} title={marketplaceTitle} height="30"/>
    )
}

export default MarketplaceLogo;