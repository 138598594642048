import styled from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';
import MuiChip from '@material-ui/core/Chip';

export const TextField = styled(MuiTextField)`
    margin: 0px 10px !important;

    & .MuiFormLabel-root {
        font-family: 'Roboto', sans-serif !important;
        font-weight: bold;
        color: black;
    }

    & .MuiInputBase-input {
        width: ${props => props.width +'px'}
    }

    & .MuiInputBase-input {
        font-family: 'Roboto', sans-serif !important;
        line-height: 1.2rem !important;
    }
`;

export const Chip = styled(MuiChip)`
    font-family: 'Roboto', sans-serif !important;
    font-weight: bold !important;
    font-size: 0.7rem !important;
    border-radius: 0px !important;
`;
