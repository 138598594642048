import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Paper,
    Tab,
    Tabs,
    Box,
    Grid,
    FormLabel,
    Input,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Chip,
    CircularProgress,
    Snackbar,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
} from '@material-ui/core';

import { Alert } from '@material-ui/lab'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { green } from '@material-ui/core/colors';

import moment from 'moment';

import PageTitle from '../../components/PageTitle';
import MarketplaceLogo from '../../components/MarketplaceLogo';
import Loading from '../../components/Loading';
import Field from '../../components/Field';

import Products from '../../services/Products';
import Ads from '../../services/Ads';
import Orders from '../../services/Orders';

import Errors from './errors';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const EditProducts = () => {
    const { id } = useParams();
    const [tab, setTab] = useState(0);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ads, setAds] = useState([]);
    const [orders, setOrders] = useState([]);
    const [dialogErrors, setDialogErrors] = useState({
        data: {},
        open: false
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const retrieveProduct = (id) => {
        Products.retrieve(id).then(result => {
            setProduct(result);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    const retrieveAds = (ads) => {
        let relatedAds = [];

        for (let index = 0; index < ads.length; index++) {
            const ad = ads[index];
            Ads.retrieve(ad).then(result => {
                relatedAds.push(result);
            }).catch(error => {
                console.log(error);
            })
        }

        relatedAds.sort(function (a, b) {
            return a.marketplace - b.marketplace;
        });

        setAds(relatedAds);
    }

    const retrieveOrders = (sku) => {
        Orders.list(0, 10, "&orderItems.sku=" + sku).then(result => {
            setOrders(result.docs);
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        retrieveProduct(id);
    }, [id])

    useEffect(() => {
        if (product) {
            retrieveAds(product.relatedAds);
            retrieveOrders(product.sku);
        }
    }, [product])

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const adColumns = [
        {
            id: 'marketplace',
            label: 'Marketplace',
            align: 'center',
            format: (value) => <MarketplaceLogo marketplace={value} />
        },
        {
            id: 'title',
            label: 'Título',
            align: 'left'
        },
        {
            id: 'marketplaceVariantId',
            label: 'Id Sku',
            align: 'center'
        },
        {
            id: 'active',
            label: 'Status',
            align: 'center',
            format: (active) => (active ? <Chip color="default" label="Ativo" style={{ background: green[600], color: 'white', minWidth: '60px' }} /> : <Chip color="secondary" label="Inativo" style={{ minWidth: '60px' }} />)
        },
        {
            id: 'stock',
            label: 'Estoque',
            align: 'center'
        },
        {
            id: 'price',
            label: 'Preço',
            align: 'right',
            format: (price) => (price ? price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '')
        },
        {
            id: 'salePrice',
            label: 'Preço Oferta',
            align: 'right',
            format: (salePrice) => (salePrice ? salePrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '')
        },
        {
            id: 'saleDateStart',
            label: 'Início Oferta',
            align: 'center',
            format: (saleDateStart) => (saleDateStart ? new Date(saleDateStart).toLocaleString('pt-BR') : '')
        },
        {
            id: 'saleDateEnd',
            label: 'Fim Oferta',
            align: 'center',
            format: (saleDateEnd) => (saleDateEnd ? new Date(saleDateEnd).toLocaleString('pt-BR') : '')
        },
        {
            id: 'marketplaceStatus',
            label: 'Situação',
            align: 'center',
            type: 'hybrid',
            format: (doc) => {
                let status = '';
                switch (doc.marketplaceStatus) {
                    case 1:
                        status = (<Chip color="primary" label="Pendente" />);
                        break;
                    case 2:
                        status = (<Chip color="default" label="Em análise" />);
                        break;
                    case 3:
                        status = (<Chip label="À Venda" style={{ background: green[600], color: 'white' }} />);
                        break;
                    case 9:
                        status = (<Chip color="secondary" label="Erro" onClick={() =>
                            setDialogErrors({
                                data: doc.marketplaceErrors,
                                open: true
                            })} />);
                        break;
                    default: status = (<Chip color="primary" label="Pendente" />); break;
                }
                return status;
            }
        }
    ]

    const orderColumns = [
        {
            id: 'marketplaceCreateDate',
            label: 'Data Criação',
            align: 'center',
            format: (value) => (<>{moment(value).format("DD/MM/YYYY")} <br /> <small>{moment(value).format("HH:mm:ss")}</small></>)
        },
        {
            id: 'marketplace',
            label: 'Marketplace',
            align: 'center',
            format: (value) => <MarketplaceLogo marketplace={value} />
        },
        {
            id: 'orderId',
            label: 'Pedido',
            align: 'center',
            type: 'hybrid',
            format: (order) =>
                <>
                    {order.marketplaceOrderId} <br />
                    <small>{order.sellerOrderId}</small>
                </>
        },
        {
            id: 'status',
            label: 'Status',
            align: 'center',
            format: (status) => Orders.orderStatus(status).label
        },
        {
            id: 'shipping',
            label: 'Cliente',
            align: 'left',
            format: (shipping) => shipping.name
        },
        {
            id: 'quantity',
            label: 'Quantidade',
            align: 'center',
            type: 'hybrid',
            format: (order) => {
                const orderItem = order.orderItems.find(x => x.sku === product.sku);
                return orderItem.quantity;
            }
        },
        {
            id: 'price',
            label: 'Preço',
            align: 'center',
            type: 'hybrid',
            format: (order) => {
                const orderItem = order.orderItems.find(x => x.sku === product.sku);
                return orderItem.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }
        },
        {
            id: 'salePrice',
            label: 'Preço de Oferta',
            align: 'center',
            type: 'hybrid',
            format: (order) => {
                const orderItem = order.orderItems.find(x => x.sku === product.sku);
                return (orderItem.price - orderItem.discount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }
        },
        {
            id: 'total',
            label: 'Total',
            align: 'center',
            type: 'hybrid',
            format: (order) => {
                const orderItem = order.orderItems.find(x => x.sku === product.sku);
                return orderItem.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
        },
        {
            id: 'freight',
            label: 'Frete',
            align: 'center',
            type: 'hybrid',
            format: (order) => {
                const orderItem = order.orderItems.find(x => x.sku === product.sku);
                return orderItem.freight.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
        },
    ]

    return (product ? (
        <>
            <PageTitle title="Edição de produto" />
            <Paper square style={{
                paddingBottom: '60px'
            }}>

                <Tabs
                    value={tab}
                    indicatorcolor="primary"
                    textcolor="primary"
                    onChange={handleChangeTab}
                    aria-label="Edição de produto"
                >
                    <Tab label="Produto" {...a11yProps(0)} />
                    <Tab label="Imagens" {...a11yProps(1)} />
                    <Tab label="Anúncios" {...a11yProps(2)} />
                    <Tab label="Últimos Pedidos" {...a11yProps(3)} />
                    <Tab label="Últimas Atualizações" {...a11yProps(4)} />
                </Tabs>
                {/* Cadastro */}
                <TabPanel value={tab} index={0}>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Dados Cadastrais</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Field
                                        type="text"
                                        required
                                        name="sku"
                                        label="SKU"
                                        defaultValue={product.sku}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={2}>
                                    <Field
                                        type="select"
                                        required
                                        name="active"
                                        label="Ativo"
                                        value={!!product?.active}
                                        options={[{
                                            label: 'Sim',
                                            value: true
                                        },
                                        {
                                            label: 'Não',
                                            value: false
                                        }]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        type="text"
                                        required
                                        name="title"
                                        label="Título"
                                        defaultValue={product.title}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        type="text"
                                        multiline
                                        rows={10}
                                        required
                                        name="description"
                                        label="Descrição"
                                        defaultValue={product.description}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Field
                                        type="text"
                                        required
                                        name="brand"
                                        label="Marca"
                                        defaultValue={product.brand}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Field
                                        type="text"
                                        required
                                        name="ean"
                                        label="EAN"
                                        defaultValue={product.ean}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Field
                                        type="text"
                                        required
                                        name="stock"
                                        label="Estoque"
                                        defaultValue={product.stock}
                                        fullWidth={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Field
                                        type="number"
                                        format="float"
                                        required
                                        name="price"
                                        label="Preço"
                                        defaultValue={product.price}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Field
                                        type="number"
                                        format="float"
                                        name="salePrice"
                                        label="Preço de Oferta"
                                        defaultValue={product.salePrice}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Field
                                        type="datetime"
                                        name="saleDateStart"
                                        label="Início Oferta"
                                        value={product.saleDateStart || null}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Field
                                        type="datetime"
                                        name="saleDateEnd"
                                        label="Fim Oferta"
                                        value={product.saleDateEnd || null}
                                        s />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Field
                                        type="number"
                                        format="integer"
                                        required
                                        name="weight"
                                        label="Peso"
                                        defaultValue={product.weight}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Field
                                        type="number"
                                        format="integer"
                                        required
                                        name="height"
                                        label="Altura"
                                        defaultValue={product.height}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Field
                                        type="number"
                                        format="integer"
                                        required
                                        name="width"
                                        label="Largura"
                                        defaultValue={product.width}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Field
                                        type="number"
                                        format="integer"
                                        required
                                        name="length"
                                        label="Comprimento"
                                        defaultValue={product.length}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Atributos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                {product?.attributes?.map(attribute => (
                                    <Grid item xs={12} md={6} key={attribute.key}>
                                        <Field
                                            type="text"
                                            name={attribute.key}
                                            label={attribute.key}
                                            defaultValue={attribute.value}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography>Especificações</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                {product?.specifications && Object.keys(product?.specifications).map(spec => (
                                    <Grid item xs={12} key={spec}>
                                        <Field
                                            type="text"
                                            multiline={product.specifications[spec].length > 60}
                                            rows={5}
                                            name={spec}
                                            label={spec.charAt(0).toUpperCase() + spec.replace(/_/g, ' ').slice(1)}
                                            defaultValue={product.specifications[spec]}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </TabPanel>
                {/* Imagens */}
                <TabPanel value={tab} index={1}>
                    <Grid container justify="center" spacing={2}>
                        {product.images.map((image, index) => (
                            <Grid item key={index}>
                                <img src={image + '?v=' + (Math.random() * 123456789).toFixed(0)} style={{ maxHeight: '200px' }} alt="imagem" />
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
                {/* Anúncios */}
                <TabPanel value={tab} index={2}>
                    {ads.length > 0 &&
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow key={'ads-header'}>
                                        {adColumns.map((column, index) => (
                                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ads.map((ad, index) => (
                                        <TableRow key={ad.id + '-' + index}>
                                            {adColumns.map((column) => (
                                                column.type !== 'hybrid' ?
                                                    <TableCell key={column.id + '-' + index} align={column.align}>{column.format ? column.format(ad[column.id]) : ad[column.id]}</TableCell>
                                                    :
                                                    <TableCell key={column.id + '-' + index} align={column.align}>{column.format(ad)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {ads.length <= 0 && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><CircularProgress size={50} /></div>}
                </TabPanel>
                {/* Orders */}
                <TabPanel value={tab} index={3}>
                    {orders.length > 0 &&
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow key={'orders-header'}>
                                        {orderColumns.map((column, index) => (
                                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders.map((ad, index) => (
                                        <TableRow key={ad.id + '-' + index}>
                                            {orderColumns.map((column) => (
                                                column.type !== 'hybrid' ?
                                                    <TableCell key={column.id + '-' + index} align={column.align}>{column.format ? column.format(ad[column.id]) : ad[column.id]}</TableCell>
                                                    :
                                                    <TableCell key={column.id + '-' + index} align={column.align}>{column.format(ad)}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    {orders.length <= 0 && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}><CircularProgress size={50} /></div>}
                </TabPanel>
                <TabPanel value={tab} index={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={2} style={{ textAlign: 'right', alignSelf: 'center' }}>
                            <FormLabel>Cadastro</FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <Input
                                fullWidth
                                readOnly
                                name="lastModified[productDate]"
                                defaultValue={product.lastModified.productDate ? moment(product.lastModified.productDate).format("DD/MM/YYYY - HH:mm:ss") : ''}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', alignSelf: 'center' }}>
                            <FormLabel>Estoque</FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <Input
                                fullWidth
                                readOnly
                                name="lastModified[stockDate]"
                                defaultValue={product.lastModified.stockDate ? moment(product.lastModified.stockDate).format("DD/MM/YYYY - HH:mm:ss") : ''}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right', alignSelf: 'center' }}>
                            <FormLabel>Preço</FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <Input
                                fullWidth
                                readOnly
                                name="lastModified[priceDate]"
                                defaultValue={product.lastModified.priceDate ? moment(product.lastModified.priceDate).format("DD/MM/YYYY - HH:mm:ss") : ''}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <Errors
                open={dialogErrors.open}
                data={dialogErrors.data}
                onClose={() => setDialogErrors({
                    data: {},
                    open: false
                })}
                confirm={() => setDialogErrors({
                    data: {},
                    open: false
                })}
            />
        </>
    ) : (
        <Loading open={loading} />
    )
    )
}

export default EditProducts;