import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MainContainer from './components/MainContainer';

import Login from "./pages/Login";
import MultiFactor from "./pages/MultiFactor";
import Dashboard from "./pages/Dashboard";
import ListProducts from "./pages/ListProducts";
import EditProducts from "./pages/EditProducts";
import ProductCategorization from "./pages/ProductCategorization";
import ListAds from "./pages/ListAds";
import ListOrders from "./pages/ListOrders";
import EditOrders from "./pages/EditOrders";
import EditStore from "./pages/EditStore";

const Routes = () => {
	const authenticated = !!localStorage.getItem('user-data');
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/multifactor" component={MultiFactor} />
				<Route exact path="/">
					{authenticated === true && <MainContainer><Dashboard /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/products">
					{authenticated === true && <MainContainer><ListProducts /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/products/categorization">
				{authenticated === true && <MainContainer><ProductCategorization /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/products/:id">
				{authenticated === true && <MainContainer><EditProducts /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/ads">
				{authenticated === true && <MainContainer><ListAds /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/orders">
				{authenticated === true && <MainContainer><ListOrders /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/orders/:id">
					{authenticated === true && <MainContainer><EditOrders /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/config">
				{authenticated === true && <MainContainer><EditStore /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route path="*">
					<Redirect to="/" />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};
export default Routes;
