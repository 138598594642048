import React, { useState } from 'react';
import { Container } from './styles';
import GlobalStyle from '../../styles/global';

import Header from '../Header';
import Drawer from '../Drawer';

const MainContainer = (props) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    const handleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    }

    return (
        <>
            <GlobalStyle />
            <Header isDrawerOpen={isDrawerOpen} handleDrawer={handleDrawer} />
            <Drawer isDrawerOpen={isDrawerOpen} />
            <Container isDrawerOpen={isDrawerOpen}>
                {props.children}
            </Container>
        </>
    )
}

export default MainContainer;