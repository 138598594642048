import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from "react-hook-form";

import { TextField, Select } from './styles';

import { InputAdornment, IconButton, MenuItem, InputLabel, Input, Checkbox, ListItemText, FormControl, Grid } from '@material-ui/core';

import moment from 'moment';

import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PendingIcon from '@material-ui/icons/Cancel';
import SyncIcon from '@material-ui/icons/Cached';

import PageTitle from '../../components/PageTitle';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import MarketplaceLogo from '../../components/MarketplaceLogo';
import { DateTimeField } from "../../components/MaskedFields";

import Orders from '../../services/Orders';
import Stores from '../../services/Stores';
import Marketplaces from '../../services/Marketplaces';
import Loading from '../../components/Loading';

import DialogErrors from './Errors';

moment.locale('pt-br');

const ListOrders = (props) => {
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0
    })

    const [marketplaces, setMarketplaces] = useState([]);
    const [status, setStatus] = useState([]);
    const [marketplace, setMarketplace] = useState([]);
    const [integrated, setIntegrated] = useState('T');
    const [dateFrom, setDateFrom] = useState(moment().startOf('month').toISOString());
    const [dateTo, setDateTo] = useState(moment().endOf('day').toISOString());

    const { register, handleSubmit, setValue } = useForm();

    const FilterOrders = useCallback(async (page, limit, filters) => {
        setData({
            page: page,
            size: limit,
            isLoading: true,
            filters: filters
        });

        let query = "";

        if (filters) {
            if (filters.searchExpression && filters.searchExpression !== "") {
                query += "&$text[$search]=" + filters.searchExpression;
            } else {
                query += "&marketplaceCreateDate[$gte]=" + dateFrom;
                query += "&marketplaceCreateDate[$lte]=" + dateTo;
            }

            if (filters.status || filters.status === 0) {
                for (let index = 0; index < filters.status.length; index++) {
                    const status = filters.status[index];
                    query += "&status=" + Orders.orderStatus(status).id;
                }
            }

            if (filters.marketplace) {
                for (let index = 0; index < filters.marketplace.length; index++) {
                    const marketplaceInfo = await Marketplaces.retrieveByName(filters.marketplace[index]);
                    query += "&marketplace=" + marketplaceInfo.marketplace;
                }
            }

            if (filters.integrated && filters.integrated !== 'T' && filters.integrated !== 'E') {
                query += "&integrated=" + filters.integrated.toString();
            }

            if (filters.integrated === 'E') {
                query += "&syncStoreStatus=ERROR"
            }
        }

        Orders.list(page, limit, query).then(result => {
            setData({
                isLoading: false,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs,
                filters: filters
            });
        }).catch(error => {
            console.log(error);
        });
    }, [dateFrom, dateTo])

    const RetrieveStores = () => {
        Stores.retrieve().then(async result => {
            let marketplaceSettings = result.marketplaceSettings.filter(x => x.active === true);
            let marketplaces = [];

            for (let index = 0; index < marketplaceSettings.length; index++) {
                const marketplace = marketplaceSettings[index];

                if (!marketplaces.find(x => x.id === marketplace.marketplace)) {
                    let marketplaceInfo = await Marketplaces.retrieveByMarketplace(marketplace.marketplace);
                    marketplaces.push({
                        id: marketplace.marketplace,
                        name: marketplaceInfo.name,
                        sellerId: String(marketplace.credentials.sellerId),
                        sellerName: marketplace.credentials.sellerName
                    });
                }
            }

            setMarketplaces(marketplaces);
        }).catch(error => {
            console.log(error);
        });
    }

    const handleChangePage = (event, newPage) => {
        FilterOrders(newPage, data.size, data.filters);
    }

    const handleChangeSize = (event) => {
        FilterOrders(0, +event.target.value, data.filters);
    }

    //const [anchorEl, setAnchorEl] = useState([]);

    // const handleOpenActionsMenu = (id, event) => {
    //     let anchors = [];
    //     anchors.push(...anchorEl);
    //     anchors[id] = event.target
    //     setAnchorEl(anchors);
    // }

    // const handleCloseActionsMenu = (id, event) => {
    //     let anchors = [];
    //     anchors.push(...anchorEl);
    //     anchors[id] = null;
    //     setAnchorEl(anchors);
    // }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
        setValue('filters[status]', event.target.value);
        setData({
            ...data,
            filters: {
                ...data.filters,
                status: event.target.value
            }
        })
    };

    const handleMarketplaceChange = (event) => {
        setMarketplace(event.target.value);
        setValue('filters[marketplace]', event.target.value);
        setData({
            ...data,
            filters: {
                ...data.filters,
                marketplace: event.target.value
            }
        })
    };

    const handleIntegratedChange = (event) => {
        setIntegrated(event.target.value);
        setValue('filters[integrated]', event.target.value);
        setData({
            ...data,
            filters: {
                ...data.filters,
                integrated: event.target.value
            }
        })
    };

    const handleDateFromChange = (date) => {
        if (date) {
            setDateFrom(date.toISOString());
            setValue('filters[dateFrom]', date.toISOString());
            setData({
                ...data,
                filters: {
                    ...data.filters,
                    dateFrom: date.toISOString()
                }
            })
        }
    };

    const handleDateToChange = (date) => {
        if (date) {
            setDateTo(date.toISOString());
            setValue('filters[dateTo]', date.toISOString());
            setData({
                ...data,
                filters: {
                    ...data.filters,
                    dateTo: date.toISOString()
                }
            })
        }
    };

    const [dialogErrors, setDialogErrors] = useState({
        data: {},
        open: false
    });

    const handleDismissFilters = () => {
        setDateFrom(moment().startOf('month').toISOString());
        setDateTo(moment().endOf('day').toISOString());
        setIntegrated("T");
        setMarketplaces([]);
        setStatus([]);
        setMarketplace([]);
        document.getElementById("searchExpression").value = '';
        applyFilters();
    }

    const handleKeyUpSearch = (event) => {
        if (event.keyCode === 13) applyFilters({ filters: { searchExpression: event.target.value } });
    }

    const applyFilters = (filters) => {
        FilterOrders(0, 30, filters?.filters);
    }

    useEffect(() => {
        RetrieveStores();
        FilterOrders(data.page, data.size, data.filters);
        register('filters[status]');
        register('filters[marketplace]');
        register('filters[integrated]');
        register('filters[dateFrom]');
        register('filters[dateTo]');

    }, [data.page, data.size, data.filters, register, FilterOrders]);

    const columns = [
        {
            id: 'marketplaceCreateDate',
            label: 'Data',
            align: 'center',
            format: (date) => (
                <>
                    {moment(date).format('DD/MM/YYYY')} <br />
                    <small>{moment(date).format('HH:mm:ss')}</small>
                </>
            )
        },
        {
            id: 'marketplaceOrderId',
            label: 'Pedido',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <>
                    {doc.customOrderId ? doc.customOrderId : doc.marketplaceOrderId}<br />
                    <small style={{ color: 'grey' }}>{doc.sellerOrderId}</small>
                </>
            )
        },
        {
            id: 'customer',
            label: 'Cliente',
            align: 'left',
            type: 'hybrid',
            format: (doc) => (
                <>
                    {doc.billing?.name}<br />
                    <small style={{ color: 'grey' }}>{doc.shipping?.name}</small>
                </>
            )
        },
        {
            id: 'total',
            label: 'Valor',
            align: 'right',
            format: (value) => (value ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '')
        },
        {
            id: 'freight',
            label: 'Frete',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <>
                    {doc.freight ? doc.freight.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}
                    <br />
                    {doc.marketplace === 11 ?
                        <>
                            {doc.shippingMethod === "correiosship_correiosship_04669" && <small style={{ color: 'grey' }}>Correios - PAC</small>}
                            {doc.shippingMethod === "correiosship_correiosship_04162" && <small style={{ color: 'grey' }}>Coreios - Sedex</small>}
                            {doc.shippingMethod.includes("matrixrate") && <small style={{ color: 'grey' }}>Frete Grátis</small>}
                        </>
                        :
                        doc.orderType === 2 ? <small style={{ color: 'grey' }}>Fulfillment</small> : <small style={{ color: 'grey' }}>{doc.shippingMethod}</small>

                    }
                </>
            )
        },
        {
            id: 'estimatedDelivery',
            label: 'Entrega Estimada',
            align: 'center',
            format: (value) => (value ? moment(value).format('DD/MM/YYYY') : '')
        },
        {
            id: 'marketplace',
            label: 'Marketplace',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <>
                    <MarketplaceLogo marketplace={doc.marketplace} />
                    {doc.sellerId && (
                        <>
                            <br />
                            <small>{doc.sellerName}</small>
                        </>
                    )}
                    {doc.customOrderId && doc.customOrderId !== doc.marketplaceOrderId && (
                        <>
                            <br /><small style={{ color: 'grey' }}>{doc.marketplaceOrderId}</small>
                        </>
                    )}
                </>
            )
        },
        {
            id: 'status',
            label: 'Situação',
            align: 'center',
            format: (value) => {
                let status = Orders.orderStatus(value);
                return status.label;
            }
        },
        {
            id: 'syncStoreStatus',
            label: 'Status',
            align: 'left',
            type: 'hybrid',
            format: (order) => {
                let syncStatus = '';

                if (order.syncStoreStatus) {
                    switch (order.syncStoreStatus) {
                        case 'PENDING':
                            if (order.sellerOrderId) {
                                syncStatus = <div style={{ width: '-webkit-fill-available', display: 'flex', alignItems: 'end', justifyContent: 'left' }}><DoneIcon fontSize='small' style={{ color: '#10ac84', marginRight: '3px' }} /><span>Integrado</span></div>; break;
                            } else {
                                syncStatus = <div style={{ width: '-webkit-fill-available', display: 'flex', alignItems: 'end', justifyContent: 'left' }}><PendingIcon fontSize='small' style={{ marginRight: '3px' }} color='disabled' /><span>Não integrado</span></div>; break;
                            }
                        case 'PROCESSING': syncStatus = <div style={{ width: '-webkit-fill-available', display: 'flex', alignItems: 'end', justifyContent: 'left' }}><SyncIcon fontSize='small' style={{ color: '#feca57', marginRight: '3px' }} /><span>Processando</span></div>; break;
                        case 'COMPLETE': syncStatus = <div style={{ width: '-webkit-fill-available', display: 'flex', alignItems: 'end', justifyContent: 'left' }}><DoneIcon fontSize='small' style={{ color: '#10ac84', marginRight: '3px' }} /><span>Integrado</span></div>; break;
                        case 'ERROR':
                            syncStatus = <div style={{ width: '-webkit-fill-available', display: 'flex', alignItems: 'end', justifyContent: 'left' }}><ErrorIcon fontSize='small' color='secondary' style={{ cursor: 'pointer', marginRight: '3px' }} onClick={() => setDialogErrors({
                                open: true,
                                data: order.orderErrors
                            })} /><span>Erro</span></div>;
                            break;
                        default: syncStatus = ''; break;
                    }
                } else {
                    if (order.pendingStoreSync === true && order.sellerOrderId === null) {
                        syncStatus = <div style={{ width: '-webkit-fill-available', display: 'flex', alignItems: 'end', justifyContent: 'left' }}><PendingIcon fontSize='small' style={{ marginRight: '3px' }} color='disabled' /><span>Não integrado</span></div>;
                    } else {
                        syncStatus = <div style={{ width: '-webkit-fill-available', display: 'flex', alignItems: 'end', justifyContent: 'left' }}><DoneIcon fontSize='small' style={{ color: '#10ac84', marginRight: '3px' }} /><span>Integrado</span></div>;
                    }
                }

                return syncStatus;
            }
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            format: (value) => (
                <IconButton href={"/orders/" + value} target="_blank">
                    <VisibilityIcon fontSize="small" />
                </IconButton>
            )
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <>
            <PageTitle title="Listagem de Pedidos" />
            <form>
                <FilterContainer
                    title="Filtros"
                    handleApplyFilters={handleSubmit(applyFilters)}
                    handleDismissFilters={handleDismissFilters}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                label="Filtrar por pedido, nome do cliente"
                                name="filters[searchExpression]"
                                id="searchExpression"
                                inputRef={register()}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                                }}
                                fullWidth
                                onKeyUp={handleKeyUpSearch}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="marketplace">Marketplace</InputLabel>
                                <Select
                                    id="marketplace"
                                    labelid="marketplace"
                                    multiple
                                    value={marketplace}
                                    onChange={handleMarketplaceChange}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 250
                                            },
                                        },
                                    }}
                                    size="small"
                                    fullWidth
                                >
                                    {marketplaces && marketplaces.sort((a, b) => {
                                        let sort = 0;
                                        if (a.name < b.name) {
                                            sort = -1;
                                        } else if (a.name > b.name) {
                                            sort = 1;
                                        }
                                        return sort;
                                    }).map(item => (
                                        <MenuItem key={item.name} value={item.name}>
                                            <Checkbox checked={marketplace.indexOf(item.name) > -1} />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="status">Status</InputLabel>
                                <Select
                                    id="status"
                                    labelid="status"
                                    multiple
                                    value={status}
                                    onChange={handleStatusChange}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 250
                                            },
                                        },
                                    }}
                                    size="small"
                                    fullWidth
                                >
                                    {Orders.orderStatus().map(item => (
                                        <MenuItem key={item.id} value={item.label}>
                                            <Checkbox checked={status.indexOf(item.label) > -1} />
                                            <ListItemText primary={item.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <DateTimeField
                                name="dateFrom"
                                label="Data de"
                                value={dateFrom}
                                onChange={handleDateFromChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <DateTimeField
                                name="dateTo"
                                label="Data até"
                                value={dateTo}
                                onChange={handleDateToChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="filters[integrated]"
                                select
                                label="Integração"
                                value={integrated}
                                onChange={handleIntegratedChange}
                                size="small"
                                fullWidth
                            >

                                <MenuItem key="T" value="T">Todos</MenuItem>
                                <MenuItem key="S" value="true">Integrado</MenuItem>
                                <MenuItem key="N" value="false">Não Integrado</MenuItem>
                                <MenuItem key="E" value="E">Com erro</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </FilterContainer>
            </form>
            {!isLoading && docs ?
                <DataTable
                    columns={columns}
                    docs={docs}
                    page={page}
                    size={size}
                    count={count}
                    error={error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />
                :
                isLoading && <Loading open={isLoading} />
            }
            <DialogErrors
                open={dialogErrors.open}
                title="Erros de integração"
                onClose={() => setDialogErrors({
                    data: {},
                    open: false
                })}
                data={dialogErrors.data}
                confirm={() => setDialogErrors({
                    data: {},
                    open: false
                })}
            />
        </>
    )
}

export default ListOrders;