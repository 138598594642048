import styled from 'styled-components';
import MuiButton from "@material-ui/core/Button";
import MuiMenuItem from "@material-ui/core/MenuItem";

export const Button = styled(MuiButton)`
  color: #FFF !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.8rem !important;
`;

export const MenuItem = styled(MuiMenuItem)`
  font-size: 0.9rem !important;
`;
