import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider
} from '@material-ui/core';

import moment from 'moment';

function History(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">Histórico de alterações do pedido</DialogTitle>
            <DialogContent>
                <List dense={true}>
                    {props.data && props.data.length > 0 && props.data.map((item, index) => (
                        <>
                        <ListItem key={"history-" + index}>
                            <ListItemAvatar style={{ textAlign: '-webkit-center' }}>
                                <>
                                    <Avatar alt={item.user} src="/broken-image.jpg" />
                                    <small>{item.user}</small>
                                </>
                            </ListItemAvatar>
                            <ListItemText 
                                primary={item.message} 
                                secondary={moment(item.date).format("DD/MM/YYYY HH:mm")} 
                                style={{
                                    padding: '10px 50px'
                                }}
                            />
                        </ListItem>
                        <Divider component="li" />
                        </>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    size="small"
                    onClick={props.confirm}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default History;