import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, AccordionActions, Divider, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

function FilterContainer(props) {  
    return (
        
        <Accordion style={{ marginBottom: '10px' }}>
            <AccordionSummary
                expandIcon={<FilterListIcon />}
                aria-controls="filter-content"
                id="filter-header"
            >
                {props.title}
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
            <Divider />
            <AccordionActions>
                <Button size="small" onClick={props.handleApplyFilters} color="primary">Aplicar</Button>
                <Button size="small" onClick={props.handleDismissFilters}>Cancelar</Button>
            </AccordionActions>
        </Accordion>
    );
}

export default FilterContainer;