import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html, body {
        font-family: 'Roboto', sans-serif;
        height: 100%
    }

    body {
        font-size: 0.785rem;
        background: #ecf1f8;
        color: #333;
        -webkit-font-smoothing; antialiased !important;
    }

    ul {
        list-style: none;
    }

    .MuiChip-root {
        border-radius: 0px !important;
    }
`;