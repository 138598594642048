import React, { useState } from 'react';
import { Box, Button, Menu, Divider, ListItemIcon } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { MenuItem, Avatar } from './styles';

function UserMenu() {
    let user = JSON.parse(localStorage.getItem('user-data'));

    const [userMenu, setUserMenu] = useState(null);
    
    const handleOpenUserMenu = (event) => {
        setUserMenu(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setUserMenu(null);
    };

    const handleLogout = () => {
        setUserMenu(null);
        localStorage.removeItem('user-data');
        localStorage.removeItem('multifactor');
        window.location.reload(true);
    }

    return (
        <>
            <Button aria-controls="userSettings" aria-haspopup="true" style={{ height: '100%' }} onClick={handleOpenUserMenu}>
                <Avatar alt={user.name} src="/broken-image.jpg" />
            </Button>
            <Menu
                id="userMenu"
                anchorEl={userMenu}
                keepMounted
                open={Boolean(userMenu)}
                onClose={handleCloseUserMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                style={{ fontSize: '0.8rem' }}
            >
                <Box width={200} style={{ textAlign: '-webkit-center' }}>
                    <Avatar alt={user.name} src="/broken-image.jpg" style={{ margin: '10px' }} />
                    <b>{user.name}</b>
                </Box>
                <Divider style={{ margin: '10px' }} />
                <MenuItem onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    Editar perfil
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    Sair
                </MenuItem>
            </Menu>
        </>
    )
}

export default UserMenu;