import React, { useState } from 'react';

import {
    TextField,
    CircularProgress
} from "@material-ui/core";

import {
    Autocomplete
} from "@material-ui/lab";

import Marketplaces from "../../services/Marketplaces";

function MarketplacesComboBox(props) {

    const [listaMarketplaces, setListaMarketplaces] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListMarketplaces = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][name][$regex]=" + event.target.value;
            filters += "&$or[0][name][$options]=gi";
        }

        setListaMarketplaces({ ...listaMarketplaces, loading: true });

        Marketplaces.list(filters).then((response) => {
            setListaMarketplaces({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id="marketplaces"
            size="small"
            fullWidth
            open={listaMarketplaces.open}
            onOpen={handleListMarketplaces}
            onClose={() => {
                setListaMarketplaces({ ...listaMarketplaces, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListMarketplaces}
            getOptionSelected={(option, value) => option.marketplace === value.marketplace}
            getOptionLabel={(option) => option.name}
            options={listaMarketplaces.options}
            loading={listaMarketplaces.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Marketplace"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaMarketplaces.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

export { MarketplacesComboBox };