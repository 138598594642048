import styled from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';
import MuiSelect from '@material-ui/core/Select';
import MuiChip from '@material-ui/core/Chip';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { green, red } from '@material-ui/core/colors';

export const TextField = styled(MuiTextField)`
    margin-right: 10px !important;

    & .MuiFormLabel-root {
        font-family: 'Roboto', sans-serif !important;
    }

    & .MuiInputBase-input {
        width: ${props => props.width +'px'}
    }

    & .MuiInputBase-input {
        font-family: 'Roboto', sans-serif !important;
        line-height: 1.2rem !important;
    }
`;

export const Chip = styled(MuiChip)`
    font-family: 'Roboto', sans-serif !important;
    font-size: 0.6rem !important;
    border-radius: 0px !important;
    width: 100px !important;
    color: ${props => {
        let color = '';

        switch (props.bgcolor) {
            case 'success': color = 'white'; break;
            case 'danger': color = 'white'; break;
            default:
                break;
        }

        return (color);
    }} !important;

    background-color: ${props => {
        let color = '';

        switch (props.bgcolor) {
            case 'success': color = green[800]; break;
            case 'danger': color = red[600]; break;
            default:
                break;
        }

        return (color);
    }} !important;
`;

export const Select = styled(MuiSelect)`
    margin-right: 10px !important;

    & .MuiInputBase-input {
        padding: 3px 0 7px;
        width: ${props => props.width +'px'}
    }
`;

export const DatePicker = styled(KeyboardDatePicker)`
    margin-right: 10px !important;
    
    & .MuiInputBase-input {
        width: ${props => props.width +'px'} !important
    }
`;