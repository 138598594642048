import { makeStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 'inherit',
        height: 'inherit',
        margin: 0,
        padding: 0
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 50px)',
        alignItems: 'center',
        justifyContent: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: indigo[500],
        color: 'white',
        '&:hover': {
            backgroundColor: indigo[700],
        },
    },
}));

export default useStyles;