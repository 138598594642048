import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

function Errors(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">Erros da integração</DialogTitle>
            <DialogContent>
                <List dense={true}>
                    {props.data && props.data.length > 0 && props.data.map((error, index) => (
                        <ListItem key={"errors-" + index}>
                            <ListItemText primary={error} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    size="small"
                    onClick={props.confirm}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default Errors;