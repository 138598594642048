import React, { useState, useEffect, useCallback } from 'react';
import {
    InputAdornment,
    IconButton,
    // Menu,
    MenuItem
} from '@material-ui/core';

import { useForm } from "react-hook-form";

//import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import PageTitle from '../../components/PageTitle';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';

import { TextField, Chip } from './styles';

import Products from '../../services/Products';

const ListProducts = () => {
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0
    })

    // const [anchorEl, setAnchorEl] = useState([]);
    const [status, setStatus] = useState('T');
    const [stock, setStock] = useState('T');
    const [price, setPrice] = useState('T');

    const { register, handleSubmit, setValue } = useForm();

    const FilterProducts = useCallback((page, limit, filters) => {
        setData({
            page: page,
            size: limit,
            isLoading: true
        })

        Products.list(page, limit, filters).then(result => {
            setData({
                isLoading: false,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs
            });
        }).catch(error => {
            console.log(error);
        });
    },[]);

    useEffect(() => {
        FilterProducts(data.page, data.size);
        register('filters[status]');
        register('filters[stock]');
        register('filters[price]');

    }, [data.page, data.size, register, FilterProducts]);

    const handleChangePage = (event, newPage) => {
        const { size } = data;
        FilterProducts(newPage, size);
    }

    const handleChangeSize = (event) => {
        FilterProducts(0, +event.target.value);
    }

    const columns = [
        {
            id: 'thumbnail',
            label: 'Imagem',
            align: 'center',
            format: (value) => (<img src={value} alt='Imagem' />)
        },
        { id: 'sku', label: 'SKU', align: 'center' },
        { id: 'title', label: 'Título' },
        {
            id: 'stock',
            label: 'Estoque',
            align: 'center'
        },
        {
            id: 'price',
            label: 'Preço',
            align: 'right',
            format: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        {
            id: 'active',
            label: 'Status',
            align: 'center',
            format: (value) => (value ? (<Chip color="primary" size="small" label="Ativo" />) : (<Chip color="secondary" size="small" label="Inativo" />))
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            width: '120px',
            format: (value) => (
                <>
                    <IconButton href={"/products/" + value} target="_blank" >
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                    {/* <IconButton
                        aria-label="mais"
                        aria-controls={value}
                        aria-haspopup="true"
                        onClick={e => handleOpenActionsMenu(value, e)}
                    >
                        <MoreVertIcon fontSize="small" />
                    </IconButton>
                    <Menu
                        id={value}
                        anchorEl={anchorEl[value]}
                        keepMounted
                        open={Boolean(anchorEl[value])}
                        onClose={e => handleCloseActionsMenu(value, e)}
                    >
                        <MenuItem onClick={e => handleCloseActionsMenu(value, e)}>Visualizar Anúncios</MenuItem>
                        <MenuItem onClick={e => handleCloseActionsMenu(value, e)}>Últimos Pedidos</MenuItem>
                        <MenuItem onClick={e => handleCloseActionsMenu(value, e)}>Log de Alterações</MenuItem>
                    </Menu> */}
                </>
            )
        }
    ];

    // const handleOpenActionsMenu = (id, event) => {
    //     let anchors = [];
    //     anchors.push(...anchorEl);
    //     anchors[id] = event.target
    //     setAnchorEl(anchors);
    // }

    // const handleCloseActionsMenu = (id, event) => {
    //     let anchors = [];
    //     anchors.push(...anchorEl);
    //     anchors[id] = null;
    //     setAnchorEl(anchors);
    // }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
        setValue('filters[status]', event.target.value);
    };

    const handleStockChange = (event) => {
        setStock(event.target.value);
        setValue('filters[stock]', event.target.value);
    };

    const handlePriceChange = (event) => {
        setPrice(event.target.value);
        setValue('filters[price]', event.target.value);
    };

    const applyFilters = (filters) => {
        let query = "";

        if (filters) {
            filters = filters.filters;

            if (filters.searchExpression && filters.searchExpression !== "") {
                query += "&$or[0][sku][$regex]=" + filters.searchExpression;
                query += "&$or[0][sku][$options]=gi";
                query += "&$or[1][title][$regex]=" + filters.searchExpression;
                query += "&$or[1][title][$options]=gi";
                query += "&$or[2][customId][$regex]=" + filters.searchExpression;
                query += "&$or[2][customId][$options]=gi";
            }

            if (filters.status && filters.status !== "T") {
                if (filters.status === "A") {
                    query += "&active=true"
                } else {
                    query += "&active=false"
                }
            }

            if (filters.stock && filters.stock !== "T") {
                if (filters.stock === "S") {
                    query += "&stock[$gt]=0"
                } else {
                    query += "&stock[$lte]=0"
                }
            }

            if (filters.price && filters.price !== "T") {
                if (filters.price === "S") {
                    query += "&price[$gt]=0"
                } else {
                    query += "&price[$lte]=0"
                }
            }
        } else {
            setValue('filters[searchExpression]', '');
            setValue('filters[status]', 'T');
            setValue('filters[stock]', 'T');
            setValue('filters[price]', 'T');
            setStatus('T');
            setStock('T');
            setPrice('T');
        }

        FilterProducts(data.page, data.size, query);
    }

    const handleDismissFilters = () => {
        applyFilters();
    }

    const handleKeyUpSearch = (event) => {
        if (event.keyCode === 13) applyFilters({ filters: { searchExpression: event.target.value } });
    }

    const { isLoading, docs, page, size, count, error } = data;

    return (!isLoading && docs ?
        <>
            <PageTitle title="Listagem de Produtos" />
            <form onSubmit={handleSubmit(applyFilters)}>
                <FilterContainer
                    title="Filtros"
                    handleApplyFilters={handleSubmit(applyFilters)}
                    handleDismissFilters={handleDismissFilters}
                >
                    <TextField
                        label="Filtrar por sku ou título"
                        name="filters[searchExpression]"
                        inputRef={register()}
                        size="small"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>,
                        }}
                        width="250"
                        onKeyUp={handleKeyUpSearch}
                    />
                    <TextField
                        select
                        label="Status"
                        value={status}
                        onChange={handleStatusChange}
                        size="small"
                        width="100"
                    >

                        <MenuItem key="T" value="T">Todos</MenuItem>
                        <MenuItem key="A" value="A">Ativos</MenuItem>
                        <MenuItem key="I" value="I">Inativos</MenuItem>
                    </TextField>
                    <TextField
                        name="filters[stock]"
                        select
                        label="Estoque"
                        value={stock}
                        onChange={handleStockChange}
                        size="small"
                        width="100"
                    >

                        <MenuItem key="T" value="T" selected>Todos</MenuItem>
                        <MenuItem key="S" value="S">Com Estoque</MenuItem>
                        <MenuItem key="N" value="N">Sem Estoque</MenuItem>
                    </TextField>
                    <TextField
                        name="filters[price]"
                        select
                        label="Preço"
                        value={price}
                        onChange={handlePriceChange}
                        size="small"
                        width="100"
                    >

                        <MenuItem key="T" value="T">Todos</MenuItem>
                        <MenuItem key="S" value="S">Com Preço</MenuItem>
                        <MenuItem key="N" value="N">Sem Preço</MenuItem>
                    </TextField>
                </FilterContainer>
            </form>
            <DataTable
                columns={columns}
                docs={docs}
                page={page}
                size={size}
                count={count}
                error={error}
                handleChangePage={handleChangePage}
                handleChangeSize={handleChangeSize}
            />
        </> : 
        isLoading && <Loading open={isLoading} />
    )
}

export default ListProducts;