import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Divider
} from '@material-ui/core';

import moment from 'moment';

export default function Errors(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <List dense>
                    {props.data && props.data.length > 0 && props.data.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                {item.user &&
                                    <ListItemAvatar style={{ textAlign: '-webkit-center' }}>
                                        <>
                                            <Avatar alt={item.user} src="/broken-image.jpg" />
                                            <small>{item.user}</small>
                                        </>
                                    </ListItemAvatar>
                                }
                                <ListItemText
                                    primary={item.message}
                                    secondary={moment(item.date).format("DD/MM/YYYY HH:mm")}
                                    style={{
                                        padding: '10px 50px'
                                    }}
                                />
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    size="small"
                    onClick={props.confirm}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}