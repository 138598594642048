const postAuth = (auth) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        let url = '/api/auth';
        let options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(auth)
        };

        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                resolve(data);
            });
    })
}

const validate = (auth) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        let url = '/api/users/validate';
        let options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(auth)
        };

        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                resolve(data);
            });
    })
}

const users = { postAuth, validate };

export default users;