import React from 'react';
import MenuItem from '../MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import { Container, Logo, Menu, SubMenu, SubMenuItem, Link } from './styles';

export default function Drawer(props) {
    const useStyles = makeStyles((theme) => ({
        logo: {
            fill: 'white'
        }
    }))

    const classes = useStyles();

    return (
        <Container isDrawerOpen={props.isDrawerOpen}>
            <Logo isDrawerOpen={props.isDrawerOpen}>
                <svg className={classes.logo} viewBox="0 0 412.383 77.74">
                    <use xlinkHref={process.env.PUBLIC_URL + '/logo.svg#logo'}></use>
                </svg>
                {/* <object data={process.env.PUBLIC_URL + '/logo.svg'} type="image/svg+xml" aria-labelledby="Buddemeyer" className={classes.logo} /> */}
                {/* <img src={process.env.PUBLIC_URL+'/logo_branco.png'} alt="Buddemeyer" /> */}
            </Logo>
            <Menu isDrawerOpen={props.isDrawerOpen}>
                <MenuItem to='/' title='Dashboard' />
                <MenuItem title='Produtos' hasChildren>
                    <SubMenu>
                        <SubMenuItem>
                            <Link href="/products">Produtos</Link>
                        </SubMenuItem>
                        {/* <SubMenuItem>
                            <Link href="/ads">Anúncios</Link>
                        </SubMenuItem> */}
                        <SubMenuItem>
                            <Link href="/products/categorization">Categorização</Link>
                        </SubMenuItem>
                    </SubMenu>
                </MenuItem>
                <MenuItem to='/orders' title='Pedidos' />
                {/* <MenuItem to='/config' title='Configurações'/> */}
            </Menu>
        </Container>
    )
}