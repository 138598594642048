import React, { useEffect, useState } from "react";
import { Menu } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { MenuItem, Button } from './styles';

function StoreMenu() {
	const [storeMenu, setStoreMenu] = useState(null);
	const user = JSON.parse(localStorage.getItem("user-data"));

	const handleOpenStoreMenu = (event) => {
		setStoreMenu(event.currentTarget);
	};

	const handleSelectStoreMenu = (storeId, event) => {
		user.selectedStore = user.stores.findIndex((x) => x.storeId === storeId);
		localStorage.setItem("user-data", JSON.stringify(user));
		setStoreMenu(null);
		window.location.reload();
	};

	const handleCloseStoreMenu = () => {
		setStoreMenu(null);
	};

	useEffect(() => {
		if(!user.stores){
			localStorage.clear()
			window.location.reload()
		}
	}, [user])

	return (user && user.stores ?
		<React.Fragment>
			<Button
				aria-controls="changeStore"
				aria-haspopup="true"
				style={{ height: "100%" }}
				onClick={handleOpenStoreMenu}
				endIcon={<KeyboardArrowDownIcon />}
			>
				{user?.stores[user.selectedStore].nickname}
			</Button>
			<Menu
				id="storeMenu"
				anchorEl={storeMenu}
				keepMounted
				open={Boolean(storeMenu)}
				onClose={handleCloseStoreMenu}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				transformOrigin={{ vertical: "top", horizontal: "center" }}
			>
				{user.stores && user.stores.map((store) => (
					<MenuItem
						key={store.storeId}
						onClick={(e) => handleSelectStoreMenu(store.storeId, e)}
					>
						{store.nickname}
					</MenuItem>
				))}
			</Menu>
		</React.Fragment>
		: ''
	);
}

export default StoreMenu;
