import React, { useState, useEffect, useCallback } from 'react';
import {
    InputAdornment,
    IconButton,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
    TextField,
    Grid,
    Select
} from '@material-ui/core';

import { useForm } from "react-hook-form";

import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import PageTitle from '../../components/PageTitle';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import MarketplaceLogo from '../../components/MarketplaceLogo';

import { Chip } from './styles';

import Ads from '../../services/Ads';
import Stores from '../../services/Stores';
import Marketplaces from '../../services/Marketplaces';

const ListProducts = (props) => {
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0
    })
    const [marketplaces, setMarketplaces] = useState([]);
    const [marketplace, setMarketplace] = useState([]);
    const [status, setStatus] = useState('T');
    const [stock, setStock] = useState('T');
    const [price, setPrice] = useState('T');
    const { register, handleSubmit, setValue } = useForm();

    const FilterAds = useCallback((page, limit, filters) => {
        setData({
            page: page,
            size: limit,
            isLoading: true
        })

        Ads.list(page, limit, filters).then(result => {
            setData({
                isLoading: false,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs
            });
        }).catch(error => {
            console.log(error);
        });
    },[])

    const RetrieveStores = () => {
        Stores.retrieve().then(async result => {
            let marketplaceSettings = result.marketplaceSettings.filter(x => x.active === true);
            let marketplaces = [];

            for (let index = 0; index < marketplaceSettings.length; index++) {
                const marketplace = marketplaceSettings[index];

                if (!marketplaces.find(x => x.id === marketplace.marketplace)) {
                    let marketplaceInfo = await Marketplaces.retrieveByMarketplace(marketplace.marketplace);
                    marketplaces.push({
                        id: marketplace.marketplace,
                        name: marketplaceInfo.name,
                        sellerId: String(marketplace.credentials.sellerId),
                        sellerName: marketplace.credentials.sellerName
                    });
                }
            }

            setMarketplaces(marketplaces);
        }).catch(error => {
            console.log(error);
        });
    }

    const handleChangePage = (event, newPage) => {
        const { size } = data;
        FilterAds(newPage, size);
    }

    const handleChangeSize = (event) => {
        FilterAds(0, +event.target.value);
    }

    const handleMarketplaceChange = (event) => {
        setMarketplace(event.target.value);
        setValue('filters[marketplace]', event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
        setValue('filters[status]', event.target.value);
    };

    const handleStockChange = (event) => {
        setStock(event.target.value);
        setValue('filters[stock]', event.target.value);
    };

    const handlePriceChange = (event) => {
        setPrice(event.target.value);
        setValue('filters[price]', event.target.value);
    };

    const handleDismissFilters = () => {
        applyFilters();
    }

    const handleKeyUpSearch = (event) => {
        if (event.keyCode === 13) applyFilters({ filters: { searchExpression: event.target.value } });
    }

    const applyFilters = useCallback(async (filters) => {
        let query = "";

        if (filters) {
            filters = filters.filters;

            if (filters.searchExpression && filters.searchExpression !== "") {
                query += "&$or[0][sku][$regex]=" + filters.searchExpression;
                query += "&$or[0][sku][$options]=gi";
                query += "&$or[1][title][$regex]=" + filters.searchExpression;
                query += "&$or[1][title][$options]=gi";
                query += "&$or[2][customId][$regex]=" + filters.searchExpression;
                query += "&$or[2][customId][$options]=gi";
            }

            if (filters.marketplace) {
                for (let index = 0; index < filters.marketplace.length; index++) {
                    const marketplace = filters.marketplace[index];
                    const marketplaceInfo = await Marketplaces.retrieveByName(marketplace);
                    query += "&marketplace=" + marketplaceInfo.marketplace;
                }
            }

            if (filters.status && filters.status !== "T") {
                if (filters.status === "A") {
                    query += "&active=true"
                } else {
                    query += "&active=false"
                }
            }

            if (filters.stock && filters.stock !== "T") {
                if (filters.stock === "S") {
                    query += "&stock[$gt]=0"
                } else {
                    query += "&stock[$lte]=0"
                }
            }

            if (filters.price && filters.price !== "T") {
                if (filters.price === "S") {
                    query += "&price[$gt]=0"
                } else {
                    query += "&price[$lte]=0"
                }
            }
        }

        FilterAds(0, data.size, query);
    },[data.size, FilterAds])

    useEffect(() => {
        applyFilters()
        RetrieveStores();
        register('filters[marketplace]');
        register('filters[status]');
        register('filters[stock]');
        register('filters[price]');

    }, [data.page, data.size, register, applyFilters]);

    const columns = [
        {
            id: 'thumbnail',
            label: 'Imagem',
            align: 'center',
            format: (value) => (<img src={value} alt='Imagem' />)
        },
        { 
            id: 'marketplaceProductId', 
            label: 'ID Produto', 
            align: 'center' 
        },
        { 
            id: 'marketplaceVariantId', 
            label: 'ID Variante', 
            align: 'center' 
        },
        { 
            id: 'sku', 
            label: 'SKU', 
            align: 'center' 
        },
        { id: 'title', label: 'Título' },
        {
            id: 'marketplace',
            label: 'Marketplace',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <>
                    <MarketplaceLogo marketplace={doc.marketplace} />
                    {doc.sellerName && <small><br />{doc.sellerName}</small>}
                    {doc.listingTypeId && <small><br />{doc.listingTypeId === 'gold_special' ? 'Clássico' : (doc.listingTypeId === 'gold_pro' ? 'Premium' : '')}</small>}

                </>
            )
        },
        {
            id: 'stock',
            label: 'Estoque',
            align: 'center'
        },
        {
            id: 'price',
            label: 'Preço',
            align: 'right',
            format: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        {
            id: 'active',
            label: 'Status',
            align: 'center',
            format: (value) => (value ? (<Chip color="primary" size="small" label="Ativo" />) : (<Chip color="secondary" size="small" label="Inativo" />))
        },
        {
            id: '_id',
            label: 'Ações',
            align: 'center',
            format: (value) => (
                <>
                    <IconButton href={"/ads/" + value}>
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (!isLoading && docs ? (
        <>
            <PageTitle title="Listagem de Anúncios" />
            <form onSubmit={handleSubmit(applyFilters)}>
                <FilterContainer
                    title="Filtros"
                    handleApplyFilters={handleSubmit(applyFilters)}
                    handleDismissFilters={handleDismissFilters}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <TextField
                                label="Filtrar por sku ou título"
                                name="filters[searchExpression]"
                                inputRef={register()}
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                                }}
                                onKeyUp={handleKeyUpSearch}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="marketplace">Marketplace</InputLabel>
                                <Select
                                    id="marketplace"
                                    labelid="marketplace"
                                    multiple
                                    value={marketplace}
                                    onChange={handleMarketplaceChange}
                                    renderValue={(selected) => selected.join(', ')}
                                    inputProps={{
                                        'fullWidth': 'true'
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 250
                                            },
                                        },
                                    }}
                                    size="small"
                                    fullWidth
                                >
                                    {marketplaces && marketplaces.map(item => (
                                        <MenuItem key={item.name} value={item.name}>
                                            <Checkbox checked={marketplace.indexOf(item.name) > -1} />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select
                                label="Status"
                                value={status}
                                onChange={handleStatusChange}
                                size="small"
                                fullWidth
                            >

                                <MenuItem key="T" value="T">Todos</MenuItem>
                                <MenuItem key="A" value="A">Ativos</MenuItem>
                                <MenuItem key="I" value="I">Inativos</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="filters[stock]"
                                select
                                label="Estoque"
                                value={stock}
                                onChange={handleStockChange}
                                size="small"
                                fullWidth
                            >

                                <MenuItem key="T" value="T" selected>Todos</MenuItem>
                                <MenuItem key="S" value="S">Com Estoque</MenuItem>
                                <MenuItem key="N" value="N">Sem Estoque</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="filters[price]"
                                select
                                label="Preço"
                                value={price}
                                onChange={handlePriceChange}
                                size="small"
                                fullWidth
                            >

                                <MenuItem key="T" value="T">Todos</MenuItem>
                                <MenuItem key="S" value="S">Com Preço</MenuItem>
                                <MenuItem key="N" value="N">Sem Preço</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </FilterContainer>
            </form>
            <DataTable
                columns={columns}
                docs={docs}
                page={page}
                size={size}
                count={count}
                error={error}
                isLoading={isLoading}
                handleChangePage={handleChangePage}
                handleChangeSize={handleChangeSize}
            />
        </>
    ) : (
            <Loading open={isLoading} />
        )
    )
}

export default ListProducts;