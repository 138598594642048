import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
} from '@material-ui/core';

import { useForm } from "react-hook-form";

function EditShipment(props) {
    const { register, handleSubmit, errors } = useForm();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">
                Editar informações do envio
                {!props.new &&
                    <small style={{ fontSize: '0.8rem' }}><br />ATENÇÃO! As informações editadas nesta seção não irão refletir no marketplace</small>
                }
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="trackingNumber"
                            label="Código de Rastreio"
                            defaultValue={props.data?.trackingNumber}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.trackingNumber}
                            helperText={errors.trackingNumber?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="trackingUrl"
                            label="URL"
                            defaultValue={props.data?.trackingUrl}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.trackingUrl}
                            helperText={errors.trackingUrl?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="method"
                            label="Método"
                            defaultValue={props.data?.method}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.method}
                            helperText={errors.method?.message}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit(props.confirm)}
                >
                    Salvar
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={props.cancel}
                >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditShipment;