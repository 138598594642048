const user = JSON.parse(localStorage.getItem('user-data'));

const retrieve = () => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("x-api-key", user.stores[user.selectedStore].storeId);
        headers.append("token", user.token);

        let url = '/api/stores/' + user.stores[user.selectedStore].storeId;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('multifactor');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const stores = { retrieve };
export default stores;