import React from "react";
import Routes from "./Routes";
import CssBaseline from "@material-ui/core/CssBaseline";

const App = () => {
    return (
    <React.Fragment>
        <CssBaseline /> 
        <Routes />
    </React.Fragment>
    )
}

export default App;
