import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import PageTitle from '../../components/PageTitle';
import { Button } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const EditProducts = (props) => {
    const [tab, setTab] = useState(0);

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleAuth = (event) => {
        let storeId = '5ebc2be23e44b42e6c42c580';
        let apiKey = '5ebc2be23e44b42e6c42c580';
        let accessToken = '+dPpGBnieJ9hTKTjNpyWqYCkGLdhnyxMn+LBpge8er8=';
  
        let url = 'https://mercadolivre.buddemeyer.com.br/auth?storeId=' + storeId + '&apiKey=' + apiKey + '&accessToken=' + encodeURIComponent(accessToken);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=650,width=1150,height=600");
    }
    
    return (
        <>
            <PageTitle title="Configurações da Loja" />
            <Paper square style={{
                paddingBottom: '60px'
            }}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        aria-label="Edição de produto"
                    >
                        <Tab label="Informações" {...a11yProps(0)} />
                        <Tab label="Marketplaces" {...a11yProps(1)} />
                        <Tab label="Transportadoras" {...a11yProps(2)} />
                    </Tabs>
                    {/* Cadastro */}
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={3}>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Button variant="contained" color="primary" onClick={handleAuth}>Autenticar ML</Button>
                    </TabPanel>
                    <TabPanel value={tab} index={2}></TabPanel>
            </Paper>
        </>
    )
}

export default EditProducts;