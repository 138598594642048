import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {
    Paper,
    Grid,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Link,
    TableRow,
    TableCell,
    Divider,
    Card,
    CardContent,
    CardActions,
    Button,
    Snackbar,
    makeStyles,
} from '@material-ui/core';

import {
    Alert
} from '@material-ui/lab';

import moment from 'moment';

import { green } from '@material-ui/core/colors';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import MarketplaceLogo from '../../components/MarketplaceLogo';
import Loading from '../../components/Loading';
import DataTable from '../../components/DataTable';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { CustomChip } from '../../components/CustomUI';

import DialogErrors from '../ListOrders/Errors';
import EditAddress from './editAddress';
import EditInvoice from './editInvoice';
import EditShipment from './editShipment';
import History from './history';

import Orders from '../../services/Orders';

const ShippingMethod = (props) => {
    let shippingMethod = props.value;
    if (props.value === "correiosship_correiosship_04669") {
        shippingMethod = "Correios - PAC";
    } else if (props.value === "correiosship_correiosship_04162") {
        shippingMethod = "Correios - SEDEX";
    } else if (props.value?.includes("matrixrate")) {
        shippingMethod = "Frete Grátis"
    } else {
        shippingMethod = props.value;
    }

    return shippingMethod;
}

const FormataCPFCNPJ = (props) => {
    if (props.value) {
        if (props.value.length === 11) {
            return props.value.substr(0, 3) + "." + props.value.substr(3, 3) + "." + props.value.substr(6, 3) + "-" + props.value.substr(9, 2);
        } else {
            return props.value.substr(0, 2) + "." + props.value.substr(2, 3) + "." + props.value.substr(5, 3) + "/" + props.value.substr(8, 4) + "-" + props.value.substr(12, 2);
        }
    } else {
        return "";
    }
}

const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    cardContent: {
        overflow: 'auto'
    }
});

const EditOrders = () => {
    const classes = useStyles();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState({});
    const [user, setUser] = useState({});
    const [dialogEditAddress, setDialogEditAddress] = useState({
        type: '',
        data: {},
        open: false
    });
    const [dialogEditInvoice, setDialogEditInvoice] = useState({
        data: {},
        open: false
    });
    const [dialogEditShipment, setDialogEditShipment] = useState({
        data: {},
        open: false
    });
    const [dialogHistory, setDialogHistory] = useState({
        data: {},
        open: false
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    });
    const [cancel, setCancel] = useState({
        open: false,
        name: "",
        id: "",
    });

    const handleChangeAddressField = (event) => {
        let updateAddress = { ...dialogEditAddress };
        updateAddress.data[event.target.name] = event.target.value;
        setDialogEditAddress(updateAddress);
    }

    const handleConfirmAddress = (event) => {
        let updateOrder = { ...order };
        let updateAddress = { ...dialogEditAddress };

        if (updateAddress.type === 'billing') {
            updateOrder.billing = updateAddress.data;

            if (!updateOrder.history) {
                updateOrder.history = [];
            }

            updateOrder.history.push({
                date: moment().toISOString(),
                message: 'Alteração do endereço de cobrança',
                user: user.name
            });
        } else {
            updateOrder.shipping = updateAddress.data;

            if (!updateOrder.history) {
                updateOrder.history = [];
            }

            updateOrder.history.push({
                date: moment().toISOString(),
                message: 'Alteração do endereço de entrega',
                user: user.name
            });
        }

        setOrder(updateOrder);

        setDialogEditAddress({
            type: '',
            data: {},
            open: false
        })
    }

    const handleChangeInvoiceField = (event) => {
        let updateInvoice = { ...dialogEditInvoice.data };
        updateInvoice[event.target.name] = event.target.value;
        setDialogEditInvoice({
            ...dialogEditInvoice,
            data: updateInvoice
        });
    }

    const handleChangeInvoiceDateField = (date, field) => {
        let updateInvoice = { ...dialogEditInvoice.data };
        if (date) {
            updateInvoice[field] = date.toISOString();
            setDialogEditInvoice({
                ...dialogEditInvoice,
                data: updateInvoice
            });
        }
    }

    const handleConfirmInvoice = (event) => {
        let updateOrder = { ...order };
        let updateInvoice = { ...dialogEditInvoice };

        if (updateOrder.status < 2) updateOrder.status = 2;

        updateOrder.invoice = updateInvoice.data;

        updateOrder.statusUpdateDate.push({
            status: 2,
            updateDate: moment().toISOString()
        })


        if (!updateOrder.history) {
            updateOrder.history = [];
        }

        updateOrder.history.push({
            date: moment().toISOString(),
            message: updateOrder.status >= 2 ? 'Alteração da nota fiscal' : 'Inclusão de nota fiscal',
            user: user.name
        });

        setOrder(updateOrder);
        setDialogEditInvoice({
            data: {},
            open: false
        })
    }

    const handleChangeShipmentField = (event) => {
        let updateShipment = { ...dialogEditShipment.data };
        updateShipment[event.target.name] = event.target.value;

        setDialogEditShipment({
            ...dialogEditShipment,
            data: updateShipment
        });
    }

    const handleConfirmShipment = (event) => {
        let updateOrder = { ...order };
        let updateShipment = { ...dialogEditShipment.data };

        if (updateOrder.status < 3) updateOrder.status = 3;

        if (!updateOrder.shipments || updateOrder.shipments.length <= 0) {
            updateOrder.shipments = [];
            updateOrder.shipments.push({
                trackingNumber: '',
                trackingUrl: '',
                method: ''
            })
        }

        updateOrder.shipments[0].trackingUrl = updateShipment.trackingUrl;
        updateOrder.shipments[0].trackingNumber = updateShipment.trackingNumber;
        updateOrder.shipments[0].method = updateShipment.method;

        updateOrder.statusUpdateDate.push({
            status: 3,
            updateDate: moment().toISOString()
        })

        if (!updateOrder.history) {
            updateOrder.history = [];
        }

        updateOrder.history.push({
            date: moment().toISOString(),
            message: updateOrder.status >= 3 ? 'Alteração dos dados de envio' : 'Inclusão de dados de envio',
            user: user.name
        });

        setOrder(updateOrder);
        setDialogEditShipment({
            data: {},
            open: false
        })
    }

    const handleClickRemoveInvoice = (event) => {
        let updateOrder = { ...order };

        if (!updateOrder.history) {
            updateOrder.history = [];
        }

        let statusIndex = updateOrder.statusUpdateDate.findIndex(x => x.status === 2);

        if (statusIndex >= 0) {
            updateOrder.statusUpdateDate.splice(statusIndex);
        }

        updateOrder.invoice = null;
        updateOrder.status = 1;

        updateOrder.history.push({
            date: moment().toISOString(),
            message: 'Nota fiscal removida',
            user: user.name
        });

        setOrder(updateOrder);
    }

    const handleClickRemoveShipment = (event) => {
        let updateOrder = { ...order };

        if (!updateOrder.history) {
            updateOrder.history = [];
        }

        let statusIndex = updateOrder.statusUpdateDate.findIndex(x => x.status === 3);

        if (statusIndex >= 0) {
            updateOrder.statusUpdateDate.splice(statusIndex);
        }

        updateOrder.shipments = [];
        updateOrder.status = 2;
        updateOrder.sentDate = null;

        updateOrder.history.push({
            date: moment().toISOString(),
            message: 'Dados de envio removidos',
            user: user.name
        });

        setOrder(updateOrder);
    }

    const handleCancelOrder = (event) => {
        setLoading(true);
        const updateOrder = { ...order };

        updateOrder.status = 9;

        updateOrder.statusUpdateDate.push({
            status: 9,
            cancelDate: moment().toISOString(),
            updateDate: moment().toISOString()
        })

        if (!updateOrder.history) {
            updateOrder.history = [];
        }

        updateOrder.history.push({
            date: moment().toISOString(),
            message: 'Pedido cancelado pelo usuário',
            user: user.name
        });

        Orders.update(order._id, updateOrder).then(success => {
            Orders.retrieve(order._id).then(response => {
                setOrder(response);
                setLoading(false);
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Pedido cancelado"
                });
            }).catch(error => {
                setLoading(false);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: "Erro ao cancelar pedido. Tente novamente"
                });
            })
        }).catch(error => {
            setLoading(false);
            setResponseStatus({
                open: true,
                severity: "error",
                message: "Erro ao cancelar pedido. Tente novamente"
            });
        })

        setCancel({
            open: false,
            name: "",
            id: "",
        });
    }

    const handleSaveOrder = (event) => {
        setLoading(true);

        let updateOrder = {};

        updateOrder.invoice = order.invoice;
        updateOrder.billing = order.billing;
        updateOrder.shipping = order.shipping;
        updateOrder.shipments = order.shipments;
        updateOrder.history = order.history;
        updateOrder.status = order.status;
        updateOrder.statusUpdateDate = order.statusUpdateDate;
        updateOrder.syncStoreStatus = "PENDING";
        updateOrder.syncStoreDate = null;
        updateOrder.webhook = true;

        Orders.update(order._id, updateOrder).then(success => {
            Orders.retrieve(order._id).then(response => {
                setOrder(response);
                setLoading(false);
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Pedido atualizado com sucesso"
                });
            }).catch(error => {
                console.log(error);
            })
        }).catch(error => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: "Erro ao salvar pedido. Tente novamente"
            });
        })
    }

    const handleDeleteOrder = () => {
        setLoading(true);
        Orders.deleteOrder(order._id).then(() => {
            setLoading(false);
            setResponseStatus({
                open: true,
                severity: "success",
                message: "Pedido atualizado com sucesso"
            });
        }).catch(error => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: error.message ? error.message : JSON.stringify(error)
            });
        })
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user-data')));
        Orders.retrieve(id).then(response => {
            setOrder(response);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }, [id])

    const columnsItems = [
        { id: 'item', label: 'Item', align: 'center' },
        {
            id: 'sku',
            label: 'SKU',
            align: 'center',
            type: 'hybrid',
            format: (item) => <Link href={"/products/" + item.relatedProductId} target="_blank">{item.sku}</Link>
        },
        {
            id: 'title',
            label: 'Produto',
            align: 'left'
        },
        {
            id: 'status',
            label: 'Situação',
            align: 'center',
            format: (status) => (status === 9 ? <CustomChip size="small" customcolor="secondary" label="Cancelado" /> : <CustomChip size="small" customcolor="success" label={Orders.orderStatus(status || order.status).label} />)
        },
        { id: 'quantity', label: 'Quant.', align: 'center' },
        {
            id: 'price',
            label: 'Preço',
            align: 'right',
            format: (value) =>
                <NumberFormat
                    displayType="text"
                    value={value}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                />
        },
        {
            id: 'discount',
            label: 'Desconto',
            align: 'right',
            format: (value) =>
                <NumberFormat
                    displayType="text"
                    value={value}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                />
        },
        {
            id: 'subtotal',
            label: 'Sub-Total',
            align: 'right',
            type: 'hybrid',
            format: (doc) => <NumberFormat
                displayType="text"
                value={doc.total}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
            />
        },
        {
            id: 'freight',
            label: 'Frete',
            align: 'right',
            format: (value) => <NumberFormat
                displayType="text"
                value={value}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
            />
        },
        {
            id: 'total',
            label: 'Total',
            align: 'right',
            type: 'hybrid',
            format: (item) => <NumberFormat
                displayType="text"
                value={item.total + item.freight}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
            />
        },
    ]

    const [dialogErrors, setDialogErrors] = useState({
        data: {},
        open: false
    });

    const orderStatus = (order) => {
        let syncStatus;

        if (order.syncStoreStatus) {
            let color = 'secondary';
            let status = 'Não Integrado';

            switch (order.syncStoreStatus) {
                case 'PENDING': color = 'primary'; status = 'Não Integrado'; break;
                case 'PROCESSING': color = 'warning'; status = 'Processando'; break;
                case 'COMPLETE': color = 'success'; status = 'Integrado'; break;
                case 'ERROR': color = 'secondary'; status = 'Erro'; break;
                default: color = 'primary'; break;
            }

            if (order.syncStoreStatus !== 'ERROR') {
                syncStatus = <CustomChip size="small" customcolor={color} label={status} />
            } else {
                syncStatus = <CustomChip size="small" customcolor={color} label={status} onClick={() => setDialogErrors({
                    open: true,
                    data: order.orderErrors
                })} />
            }
        } else {
            if (order.pendingStoreSync === true) {
                syncStatus = <CustomChip size="small" customcolor="primary" label="Não Integrado" />
            } else {
                syncStatus = <CustomChip size="small" customcolor="success" label="Integrado" />
            }
        }

        return syncStatus;
    }

    return (!loading && order ?
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                                    <MarketplaceLogo marketplace={order.marketplace} />
                                </Grid>
                                <Grid xs={12} lg={6} style={{ display: 'flex', alignItems: 'center' }}>Pedido Marketplace</Grid>
                                <Grid xs={12} lg={6}>
                                    <Typography variant="h6">{order.customOrderId ? order.customOrderId : order.marketplaceOrderId}</Typography>
                                </Grid>
                                {order.customOrderId &&
                                    <React.Fragment>
                                        <Grid item xs={12} lg={6} style={{ display: 'flex', alignItems: 'center' }}>ID do Pedido</Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="h6">{order.marketplaceOrderId}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                }
                                <Grid xs={12} lg={6} style={{ display: 'flex', alignItems: 'center' }}>Pedido ERP</Grid>
                                <Grid xs={12} lg={6}>
                                    <Typography variant="h6">
                                        {order.sellerOrderId}
                                        {!order.sellerOrderId && orderStatus(order)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions dir="rtl">
                            <Button
                                size="small"
                                disabled={!order.history || order.history?.length <= 0}
                                onClick={() => setDialogHistory({
                                    data: order.history.sort((a, b) => {
                                        if (a.date > b.date) return -1;
                                        if (a.date < b.date) return 1;
                                        return 0;
                                    }),
                                    open: true
                                })}
                            >
                                Histórico de alterações
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Card className={classes.card}>
                        <CardContent style={{ paddingBottom: 0 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Stepper alternativeLabel activeStep={order.status}>
                                        <Step key={0}>
                                            <StepLabel>
                                                Novo <br />
                                                {order.statusUpdateDate.find(x => x.status === 0) &&
                                                    <small>
                                                        {moment(order.statusUpdateDate.find(x => x.status === 0)?.updateDate).format("DD/MM/YYYY")}
                                                        <br />
                                                        {moment(order.statusUpdateDate.find(x => x.status === 0)?.updateDate).format("HH:mm:ss")}
                                                    </small>
                                                }
                                            </StepLabel>
                                        </Step>
                                        {!order.statusUpdateDate.find(x => x.status === 9) &&
                                            <Step key={1}>
                                                <StepLabel>
                                                    Aprovado <br />
                                                    {order.statusUpdateDate.find(x => x.status === 1) &&
                                                        <small>
                                                            {moment(order.statusUpdateDate.find(x => x.status === 1)?.updateDate).format("DD/MM/YYYY")}
                                                            <br />
                                                            {moment(order.statusUpdateDate.find(x => x.status === 1)?.updateDate).format("HH:mm:ss")}
                                                        </small>
                                                    }
                                                </StepLabel>
                                            </Step>
                                        }
                                        {!order.statusUpdateDate.find(x => x.status === 9) &&
                                            <Step key={2}>
                                                <StepLabel>
                                                    Faturado <br />
                                                    {order.statusUpdateDate.find(x => x.status === 2) &&
                                                        <small>
                                                            {moment(order.statusUpdateDate.find(x => x.status === 2)?.updateDate).format("DD/MM/YYYY")}
                                                            <br />
                                                            {moment(order.statusUpdateDate.find(x => x.status === 2)?.updateDate).format("HH:mm:ss")}
                                                        </small>
                                                    }
                                                </StepLabel>
                                            </Step>
                                        }
                                        {!order.statusUpdateDate.find(x => x.status === 9) &&
                                            <Step key={3}>
                                                <StepLabel>
                                                    Enviado <br />
                                                    {order.statusUpdateDate.find(x => x.status === 3) &&
                                                        <small>
                                                            {moment(order.statusUpdateDate.find(x => x.status === 3)?.updateDate).format("DD/MM/YYYY")}
                                                            <br />
                                                            {moment(order.statusUpdateDate.find(x => x.status === 3)?.updateDate).format("HH:mm:ss")}
                                                        </small>
                                                    }
                                                </StepLabel>
                                            </Step>
                                        }
                                        {!order.statusUpdateDate.find(x => x.status === 9) &&
                                            <Step key={4}>
                                                <StepLabel>
                                                    Entregue <br />
                                                    {order.statusUpdateDate.find(x => x.status === 4) &&
                                                        <small>
                                                            {moment(order.statusUpdateDate.find(x => x.status === 4)?.updateDate).format("DD/MM/YYYY")}
                                                            <br />
                                                            {moment(order.statusUpdateDate.find(x => x.status === 4)?.updateDate).format("HH:mm:ss")}
                                                        </small>
                                                    }
                                                </StepLabel>
                                            </Step>
                                        }
                                        {order.statusUpdateDate.find(x => x.status === 9) &&
                                            <Step key={9}>
                                                <StepLabel>
                                                    Cancelado <br />

                                                    <small>
                                                        {moment(order.statusUpdateDate.find(x => x.status === 9)?.updateDate).format("DD/MM/YYYY")}
                                                        <br />
                                                        {moment(order.statusUpdateDate.find(x => x.status === 9)?.updateDate).format("HH:mm:ss")}
                                                    </small>

                                                </StepLabel>
                                            </Step>
                                        }
                                    </Stepper>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => {
                                    setCancel({
                                        open: true,
                                        name: (order.customOrderId ? order.customOrderId : order.marketplaceOrderId) + ' - ' + order.billing.name,
                                        id: order.id
                                    })
                                }}
                            >
                                Cancelar Pedido
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold', margin: '0.7rem', padding: 0 }}>
                                        Endereço de cobrança
                                    </p>
                                    <Divider />
                                </Grid>
                                <Grid item xs={3}>Nome</Grid>
                                <Grid item xs={9}>{order.billing.name}</Grid>
                                <Grid item xs={3}>{order.billing.vatNumber ? (order.billing.vatNumber.length === 11 ? 'CPF' : 'CNPJ') : ''}</Grid>
                                <Grid item xs={9}><FormataCPFCNPJ value={order.billing.vatNumber} /></Grid>
                                <Grid item xs={3}>Telefone</Grid>
                                <Grid item xs={9}>{order.billing.phone}</Grid>
                                <Grid item xs={3}>E-Mail</Grid>
                                <Grid item xs={9}>{order.billing.email}</Grid>
                                <Grid item xs={3}>Endereço</Grid>
                                <Grid item xs={9}>{order.billing.streetName && (order.billing.streetName?.trim() !== '' ? order.billing.streetName + ', ' + order.billing.streetNumber : '')}</Grid>
                                <Grid item xs={3}>Complemento</Grid>
                                <Grid item xs={9}>{order.billing.streetComplement && order.billing.streetComplement?.trim() !== '' ? order.billing.streetComplement : ''}</Grid>
                                <Grid item xs={3}>Bairro</Grid>
                                <Grid item xs={9}>{order.billing.district}</Grid>
                                <Grid item xs={3}>Cidade</Grid>
                                <Grid item xs={9}>{order.billing.city && (order.billing.city?.trim() !== '' ? order.billing.city + ' - ' + order.billing.state : '')}</Grid>
                                <Grid item xs={3}>CEP</Grid>
                                <Grid item xs={9}>{order.billing.zipCode}</Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => setDialogEditAddress({
                                    type: 'billing',
                                    data: { ...order.billing, marketplace: order.marketplace },
                                    open: true
                                })}
                            >
                                Editar
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold', margin: '0.7rem', padding: 0 }}>
                                        Endereço de entrega
                                    </p>
                                    <Divider />
                                </Grid>
                                <Grid item xs={3}>Nome</Grid>
                                <Grid item xs={9}>{order.shipping.name}</Grid>
                                <Grid item xs={3}>Telefone</Grid>
                                <Grid item xs={9}>{order.shipping.phone}</Grid>
                                <Grid item xs={3}>E-Mail</Grid>
                                <Grid item xs={9}>{order.shipping.email}</Grid>
                                <Grid item xs={3}>Endereço</Grid>
                                <Grid item xs={9}>{order.shipping.streetName?.trim() !== '' ? order.shipping.streetName + ', ' + order.shipping.streetNumber : ''}</Grid>
                                <Grid item xs={3}>Complemento</Grid>
                                <Grid item xs={9}>{order.shipping.streetComplement && order.shipping.streetComplement?.trim() !== '' ? order.shipping.streetComplement : ''}</Grid>
                                <Grid item xs={3}>Bairro</Grid>
                                <Grid item xs={9}>{order.shipping.district}</Grid>
                                <Grid item xs={3}>Cidade</Grid>
                                <Grid item xs={9}>{order.shipping.city?.trim() !== '' ? order.shipping.city + ' - ' + order.shipping.state : ''}</Grid>
                                <Grid item xs={3}>CEP</Grid>
                                <Grid item xs={9}>{order.shipping.zipCode}</Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => setDialogEditAddress({
                                    type: 'shipping',
                                    data: { ...order.shipping, marketplace: order.marketplace },
                                    open: true
                                })}
                            >
                                Editar
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold', margin: '0.7rem', padding: 0 }}>
                                        Forma de pagamento
                                    </p>
                                    <Divider />
                                </Grid>
                                <Grid item xs={6}>Método</Grid>
                                <Grid item xs={6}>{order.payments[0]?.method}{order.payments[0]?.cardBrand ? ' - ' + order.payments[0]?.cardBrand : ''}</Grid>
                                <Grid item xs={6}>Parcelas</Grid>
                                <Grid item xs={6}>{order.payments[0]?.installments && order.payments[0]?.installments + 'x'}</Grid>
                                <Grid item xs={6}>Valor {order.status <= 0 ? 'Pendente' : 'Pago'}</Grid>
                                <Grid item xs={6}>
                                    <NumberFormat
                                        displayType="text"
                                        value={order.payments[0]?.value}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold', margin: '0.7rem', padding: 0 }}>
                                        Método de envio
                                    </p>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <ShippingMethod value={order.shippingMethod} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold', margin: '0.7rem', padding: 0 }}>
                                        Nota Fiscal
                                    </p>
                                    <Divider />
                                </Grid>
                                <Grid item xs={3}>Número/Serie</Grid>
                                <Grid item xs={9}>{order.invoice?.number}{order.invoice?.serial && '/' + order.invoice?.serial}</Grid>
                                <Grid item xs={3}>Chave</Grid>
                                <Grid item xs={9}>{order.invoice?.key}</Grid>
                                <Grid item xs={3}>Data/Hora</Grid>
                                <Grid item xs={9}>{order.invoice?.date && moment(order.invoice?.date).format("DD/MM/YYYY - HH:mm")}</Grid>
                                <Grid item xs={3}>XML</Grid>
                                <Grid item xs={9}></Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => setDialogEditInvoice({
                                    new: (order.status === 1),
                                    data: order.invoice ? order.invoice : { date: moment() },
                                    open: true
                                })}
                                disabled={order.status < 1 || order.status === 9}
                            >
                                {order.status <= 1 ? 'Incluir' : 'Editar'}
                            </Button>
                            {order.status === 2 &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleClickRemoveInvoice}
                                >
                                    Remover
                                </Button>
                            }
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card className={classes.card}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold', margin: '0.7rem', padding: 0 }}>
                                        Informações do envio
                                    </p>
                                    <Divider />
                                </Grid>
                                <Grid item xs={3}>Cód. Rastreio</Grid>
                                <Grid item xs={9}>{order.shipments[0]?.trackingNumber}</Grid>
                                <Grid item xs={3}>URL</Grid>
                                <Grid item xs={9}>
                                    <Link href={order.shipments[0]?.trackingUrl} target="_blank">
                                        {order.shipments[0]?.trackingUrl ? order.shipments[0]?.trackingUrl : ''}
                                    </Link>
                                </Grid>
                                <Grid item xs={3}>Método</Grid>
                                <Grid item xs={9}>{order.shipments[0]?.method}</Grid>
                                <Grid item xs={3}>Data</Grid>
                                <Grid item xs={9}>{order.statusUpdateDate.find(x => x.status === 3) && moment(order.statusUpdateDate.find(x => x.status === 3)?.updateDate).format("DD/MM/YYYY")}</Grid>
                            </Grid>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => setDialogEditShipment({
                                    new: order.status === 2,
                                    data: order.shipments[0],
                                    open: true
                                })}
                                disabled={order.status < 2 || order.status === 9}
                            >
                                {order.status <= 2 ? 'Incluir' : 'Editar'}
                            </Button>
                            {order.status === 3 &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleClickRemoveShipment}
                                >
                                    Remover
                                </Button>
                            }
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Paper square>
                        <DataTable
                            columns={columnsItems}
                            docs={order.orderItems}
                            footer={
                                <>
                                    <TableRow>
                                        <TableCell colSpan={7} align="right" style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}>
                                            Descontos
                                        </TableCell>
                                        <TableCell align="right" style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}>

                                        </TableCell>
                                        <TableCell align="right" style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}>

                                        </TableCell>
                                        <TableCell align="right" style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}>
                                            <NumberFormat
                                                value={order.discount}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={7} align="right" style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}>
                                            Totais
                                        </TableCell>
                                        <TableCell align="right" style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}>
                                            <NumberFormat
                                                value={order.subtotal}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </TableCell>
                                        <TableCell align="right" style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}>
                                            <NumberFormat
                                                value={order.freight}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </TableCell>
                                        <TableCell align="right" style={{
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                        }}>
                                            <NumberFormat
                                                value={order.total - order.discount}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                        </TableCell>
                                    </TableRow>
                                </>
                            }
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        style={{
                            color: 'white',
                            backgroundColor: green[800],
                            marginRight: '1rem'
                        }}
                        onClick={handleSaveOrder}
                    >
                        Salvar
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleDeleteOrder}
                    >
                        Excluir
                    </Button>
                </Grid>
            </Grid>
            <EditAddress
                type={dialogEditAddress.type}
                open={dialogEditAddress.open}
                handleChangeField={handleChangeAddressField}
                onClose={() => setDialogEditAddress({
                    type: '',
                    data: {},
                    open: false
                })}
                data={dialogEditAddress.data}
                confirm={handleConfirmAddress}
                cancel={() => setDialogEditAddress({
                    type: '',
                    data: {},
                    open: false
                })}
            />
            <EditInvoice
                new={dialogEditInvoice.new}
                open={dialogEditInvoice.open}
                handleChangeField={handleChangeInvoiceField}
                handleChangeDateField={handleChangeInvoiceDateField}
                onClose={() => setDialogEditInvoice({
                    data: {},
                    open: false
                })}
                data={dialogEditInvoice.data}
                confirm={handleConfirmInvoice}
                cancel={() => setDialogEditInvoice({
                    data: {},
                    open: false
                })}
            />
            <EditShipment
                new={dialogEditShipment.new}
                open={dialogEditShipment.open}
                handleChangeField={handleChangeShipmentField}
                onClose={() => setDialogEditShipment({
                    data: {},
                    open: false
                })}
                data={dialogEditShipment.data}
                confirm={handleConfirmShipment}
                cancel={() => setDialogEditShipment({
                    data: {},
                    open: false
                })}
            />
            <History
                open={dialogHistory.open}
                data={dialogHistory.data}
                onClose={() => setDialogHistory({
                    data: {},
                    open: false
                })}
                confirm={() => setDialogHistory({
                    data: {},
                    open: false
                })}
            />
            <ConfirmationDialog
                title="Tem certeza que deseja cancelar este pedido?"
                confirmTitle="Sim"
                confirm={handleCancelOrder}
                cancelTitle="Não"
                cancel={() => {
                    setCancel({
                        ...cancel,
                        open: false,
                    });
                }}
                open={cancel.open}
                onClose={() => {
                    setCancel({
                        ...cancel,
                        open: false,
                    });
                }}
            >
                {cancel.name}
            </ConfirmationDialog>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <DialogErrors
                open={dialogErrors.open}
                title="Erros de integração"
                onClose={() => setDialogErrors({
                    data: {},
                    open: false
                })}
                data={dialogErrors.data}
                confirm={() => setDialogErrors({
                    data: {},
                    open: false
                })}
            />
        </>
        :
        loading && <Loading open={loading} />
    )
}

export default EditOrders;