const user = JSON.parse(localStorage.getItem('user-data'));

const list = (page = 0, limit = 30, filters = '') => {
    return new Promise((resolve, reject) => {
        let headers = new Headers();
        headers.append("x-api-key", user.stores[user.selectedStore].storeId);
        headers.append("token", user.token);

        let url = '/api/marketplaces?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('multifactor');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const retrieveByMarketplace = (marketplace) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("x-api-key", user.stores[user.selectedStore].storeId);
        headers.append("token", user.token);

        let url = '/api/marketplaces?marketplace=' + marketplace;
        let options = {
            method: 'GET',
            headers: headers,
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('multifactor');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data.docs[0]);
            }).catch(error => {
                reject(error);
            });
    })
}

const retrieveByName = (name) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("x-api-key", user.stores[user.selectedStore].storeId);
        headers.append("token", user.token);

        let url = '/api/marketplaces?name=' + name;
        let options = {
            method: 'GET',
            headers: headers,
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('multifactor');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data.docs[0]);
            }).catch(error => {
                reject(error);
            });
    })
}

const marketplaces = { list, retrieveByMarketplace, retrieveByName };

export default marketplaces;