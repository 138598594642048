import { withStyles } from "@material-ui/styles";
import MuiChip from "@material-ui/core/Chip";

const palette = {
    primary: {
        contrastText: '#fff',
        main: '#2e86de'
    },
    secondary: {
        contrastText: '#fff',
        main: '#ee5253',
        dark: "#cd3536"
    },
    warning: {
        contrastText: '#000',
        main: '#feca57'
    },
    success: {
        contrastText: '#fff',
        main: '#10ac84'
    },
    active: {
        contrastText: '#fff',
        main: '#1dd1a1'
    },
    inactive: {
        contrastText: '#fff',
        main: '#ee5253'
    },
    background: {
        default: 'rgb(232, 254, 250, 0.7)'
    },
    header: {
        contrastText: '#fff',
        main: '#008261'
    },
    tonalOffset: 0.2
}

const CustomChip = withStyles(() => ({
    root: props => {
        const format = {}

        format.borderRadius = 0;
        format.color = palette[props.customcolor].contrastText;
        format.backgroundColor = palette[props.customcolor].main;
        format.fontSize = '0.675rem';
        format.height = 'fit-content';
        if (props.onClick) {
            format['&:hover'] = {
                backgroundColor: palette[props.customcolor].dark
            }
        }
        return format;
    },
    clickable: props => {
        const format = {}

        if (props.onClick) {
            format['&:focus'] = {
                backgroundColor: palette[props.customcolor].dark
            }
        }
        return format;
    }
}))(MuiChip);

export { CustomChip };