import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';


import useStyles from '../styles/Login';
import Users from '../services/Users';

const Login = () => {
    const classes = useStyles();

    const [formData, setFormData] = useState({
        email: null,
        password: null
    });

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const authenticate = async (event) => {
        setLoading(true);
        event.preventDefault();
        const user = await Users.postAuth(formData);

        if (user.error) {
            setError(user.error);
            setLoading(false);
        } else {
            if (user.multifactorAuth) {
                localStorage.setItem('multifactor', true);
                window.location.href = '/multifactor?code='+user.code;
            } else {
                user.selectedStore = 0;
                localStorage.setItem('user-data', JSON.stringify(user));
                window.location.reload(true);
            }
        }
    }

    const inputHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        setError(null);
        setFormData({
            ...formData,
            [nam]: val
        });
    }

    return (
        (!!!localStorage.getItem('user-data') ? (
            <Container component="main" className={classes.root}>
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="logo" style={{ height: '50px', marginBottom: '20px' }} />
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={inputHandler}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={inputHandler}
                        />
                        {error && <Alert severity="error">{error}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            disabled={loading}
                            onClick={authenticate}
                        >
                            Entrar
                            <span style={{ paddingRight: '10px' }}></span>
                            {loading && <CircularProgress size={20} />}
                        </Button>
                        <Grid container>
                            <Grid item xs style={{ textAlign: 'end' }}>
                                {/* <Link href="#" variant="body2">
                                    Esqueceu a senha?
                                </Link> */}
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box>
                    <Typography variant="body2" align="center">
                        {'Copyright © '}
                        <Link href="https://www.compassio.com.br/" target="_blank">
                            Compassio
                        </Link>{' '}
                        {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Container>
        ) : (
            <Redirect to='/' />
        ))
    );
}

export default Login;