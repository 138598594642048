import React from 'react';

import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
} from '@material-ui/core';

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export default function Field(props) {
    const selectField = (props) => (
        <FormControl
            margin="dense"
            variant="outlined"
            fullWidth
        >
            <InputLabel 
                shrink
                style={{
                    marginLeft: '-4px',
                    background: 'white',
                    paddingLeft: '0.3rem',
                    paddingRight: '0.3rem'
                }}
            >
                {props.label}
            </InputLabel>
            <Select
                {...props}
            >
                {props.options?.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )

    const multipleSelectField = (props) => (
        <FormControl
            margin="dense"
            variant="outlined"
            fullWidth
        >
            <InputLabel
                shrink
                style={{
                    marginLeft: '-4px',
                    background: 'white',
                    paddingLeft: '0.3rem',
                    paddingRight: '0.3rem'
                }}
            >
                {props.label}
            </InputLabel>
            <Select
                {...props}
                multiple
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 250
                        },
                    },
                }}
            >
                {props.options?.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        <Checkbox checked={props.value.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )

    const textField = (props) => (
        <TextField
            {...props}
            type="text"
            margin="dense"
            variant="outlined"
            fullWidth={typeof (props.fullWidth) !== 'undefined' ? props.fullWidth : true}
            InputLabelProps={{
                shrink: true
            }}
        />
    )

    const numberField = (props) => {
        const formatValue = (format, value, string) => {
            if (value && value !== '') {
                let number = value;
                switch (format) {
                    case 'integer':
                        number = number.toString();
                        number = number.replace(/[^0-9]+/g, '');
                        number = parseInt(number) || 0;
                        if (string) {
                            number = number.toLocaleString('pt-BR');
                        }
                        break;

                    case 'float':
                        number = number.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                        number = number.replace(/[^0-9]+/g, '');
                        number = parseFloat(number / 100) || 0;
                        if (string) {
                            number = number.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                        }
                        break;

                    default:
                        break;
                }

                // number = number.toString().split(/(?=(?:\d{3})+(?:\.|$))/g);
                // number = number.join(",")
                return number;
            } else {
                return value;
            }
        }

        const handleChangeNumber = (event) => {
            event.target.value = formatValue(props.format, event.target.value, true);
            event.target.dataset.value = formatValue(props.format, event.target.value, false);
            props.onChange(event);
        }

        const value = formatValue(props.format, props.defaultValue, true);

        return (
            <TextField
                {...props}
                defaultValue={value}
                type="text"
                margin="dense"
                variant="outlined"
                fullWidth
                onChange={handleChangeNumber}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    "data-value": formatValue(props.format, props.defaultValue, false)
                }}
            />)
    }

    const dateTimeField = (props) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                {...props}
                ampm={false}
                format="DD/MM/YYYY HH:mm"
                inputVariant="outlined"
                onChange={e => props.onChange(e, props.name)}
                showTodayButton
                fullWidth
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </MuiPickersUtilsProvider>
    )

    const dateField = (props) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                {...props}
                type="text"
                ampm={false}
                format="DD/MM/YYYY"
                inputVariant="outlined"
                onChange={e => props.onChange(e, props.name)}
                showTodayButton
                fullWidth
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </MuiPickersUtilsProvider>
    )

    const getType = (type) => {
        let element;
        switch (type) {
            case 'select': element = selectField(props); break;
            case 'select-multiple': element = multipleSelectField(props); break;
            case 'text': element = textField(props); break;
            case 'number': element = numberField(props); break;
            case 'datetime': element = dateTimeField(props); break;
            case 'date': element = dateField(props); break;
            default: element = selectField(props); break;
        }

        return element;
    }

    return getType(props.type);
}